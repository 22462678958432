import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';
import SocialWidget from '../Widget/SocialWidget';
import { Helmet } from 'react-helmet';

export default function AboutPage() {
  pageTitle('About');

  const funfaceData = [
    {
      title: 'Global Happy Clients',
      factNumber: '40K',
    },
    {
      title: 'Project Completed',
      factNumber: '50K',
    },
    {
      title: 'Team Members',
      factNumber: '245',
    },
    {
      title: 'Digital products',
      factNumber: '550',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <Helmet>
          <title>About Us | GC Media – Your Trusted Partner in Advertising Industry</title>
          <meta name="description" content="GC Media, is a leader in offering best solutions in advertising. We specialize in TV, OTT, Print, Radio, OOH,Creative, and Brand Building. Discover how we can help your business succeed." />
        </Helmet>
      </div>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}


      <Spacing lg='80' md='80' />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/About us.webp" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-blk">About</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_40 cs-height_lg_25" />
              <p className="cs-m0 clr-blk">Our team of experienced professionals offers silver-bullet advertising services across a wide range of industries. As a leader in innovative and effective business solutions, has over 25 years of experience in assisting our clients to reach their business goals.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We offer a comprehensive range of advertising services, including branding, Print, TV, digital, OTT, radio, and outdoor marketing, and social media management. The ability to combine the talents of our talented team of experts from different disciplines ensures that we are always pushing the boundaries and exploring new possibilities.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Allied as a 360-degree advertising agency, offer out-of-the-box and integrated solutions in accordance with the needs of our clients and harness technology and creativity to increase their brand awareness. In order to build brands through detailed audits, market strategies, achieving stable scalability, and maintaining consistency, we curated our advertising company with full transparency.</p>
              <Div className="cs-height_45 cs-height_lg_30" />
            </Div>
          </Div>
        </Div>
      </Div>

      {/* Start About Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Your trusted partner for business"
              subtitle="About Our Agency"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              We are a team of experienced professionals dedicated to providing top-notch advertising services across a variety of verticals. Since our founding in 1997, we have been committed to delivering innovative and effective solutions that help our clients achieve their business goals.
              Our company offers a comprehensive range of advertising services, including Strategy Planning, TV, Digital, Print Radio & OOH Marketing, Branding, Social media management, We take a strategic approach to each project, working closely with our clients to understand their unique needs and tailor our services to meet their specific goals.
              At our core, we believe that creativity, innovation, and collaboration are key to delivering successful campaigns. We bring together a talented team of experts from various disciplines to ensure that we are always pushing the boundaries and exploring new opportunities. Our team is made up of seasoned Strategist, Analysts, Media Planners & Buyers, Designers, and a able operations team who are passionate about their work and dedicated to delivering exceptional results.
              Over the years, we have had the privilege of working with clients across a wide range of industries, from Automobile, Healthcare, FMCG, BFSI and Technology to fashion and entertainment. Our proven track record of success has made us a trusted partner for many of our clients, and we continue to strive for excellence in everything we do.

              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_1.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div> */}
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      {/* <Div className="container">
        <FunFact
          title="Our fun fact"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
          data={funfaceData}
        />
      </Div> */}
      {/* End Fun Fact Section */}




      {/* End Why Choose Section */}

      <Spacing lg="85" md="45" />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  );
}
