import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import 'swiper/css';
import 'swiper/css/pagination';
import Hero6 from '../Hero/Hero6';

export default function ShowcasePortfolioHome() {
  pageTitle('Showcase Portfolio');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  

  const showcaseData = [
    {
      title: 'GC Media- Epitome Of <br/>Trust And Transparency',
      imgUrl: '/images/banner1.png',
      href: '/',
    },
    {
      title: 'Solution Across <br/> Multiple Media Verticles',
      imgUrl: '/images/slider_4.jpeg',
      href: '/',
    },
    {
      title: 'Unleashing Creativity Aiming <br/> For Unmatched Success',
      imgUrl: '/images/slider_3.jpeg',
      href: '/',
    },
    {
      title: 'ROI-Driven<br/> Integrated Media Agency',
      imgUrl: '/images/banner2.webp',
      href: '/',
    },
  ];

  return (
    <Hero6
     
      showcaseData={showcaseData}
    />
  );
}
