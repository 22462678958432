import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/pay'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import AboutThree from '../counterup/AboutThree'
import { Helmet } from 'react-helmet';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div>
        <Helmet>
          <title>PPC Services | GC Media – Your Trusted PPC Agency</title>
          <meta name="description" content="Boost your ROI with GC Media's expert PPC Services. Specializing in Pay-Per-Click advertising, we deliver campaigns that drive traffic and increase conversions." />
        </Helmet>
      </div>
      <PageHeading
        title='Pay Per Click'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='Pay Per Click'
      />
      <Spacing lg='50' md='50' />
      {/* End FunFact Section */}
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/pay Per Click.webp" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">PPC (Pay Per Click)</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />

              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">One of the key benefits of PPC (Pay per click) advertising is the ability to target specific audiences with precision. PPC involves creating and managing digital advertising campaigns on search engines and other platforms that charge based on the number of clicks generated by the ads.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We as an advertising cast, specialize in PPC conducting keyword research to identify relevant search terms and creating ad copy and landing pages that are optimized for conversions. This level of targeting helps to increase the relevance and effectiveness of ads, while also maximizing the return on investment for our clients.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">By developing and executing effective PPC campaigns, we help our clients to achieve their business objectives and stay ahead of the competition. Contact us today to learn more about our services and how we can help you achieve your business goals through powerful PPC Advertising.</p>
            </Div>
          </Div>
        </Div>
      </Div>


      <Spacing lg='100' md='45' />
      {/* <AboutThree/> */}

      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
