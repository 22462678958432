import React from 'react'
import { Icon } from '@iconify/react';
import ContactInfoWidgetT from './ContactInfoWidgetT';

export default function ContactInfoWidget({withIcon, title}) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
      <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span>:''}
          First Floor, H-94 Sector 63<br/> Noida, Gautam Buddh Nagar<br/> (UP) - 201301
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
          0120 - 4150110 | 4902734
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
          info@gcmedia.com
        </li>
      </ul>

    </>
  )
}
