import React from 'react';

const Data = [
    {
        image: "/images/client/Sonalika.jpg"
    },
    {
        image: "/images/client/VRL.jpg"
    },
    {
        image: "/images/client/Satmola.jpg"
    },
    {
        image: "/images/client/Nilkamal.jpg"
    },
    {
        image: "/images/client/Today-Tea.jpg"
    },
    {
        image: "/images/client/Cremica.png"
    },
    {
        image: "/images/client/Body-Care.jpg"
    },
    {
        image: "/images/client/GCL.jpg"
    },
    {
        image: "/images/client/Indra-Ivf.jpg"
    },
    {
        image: "/images/client/idp.jpg"
    },
    {
        image: "/images/client/RSPL.jpg"
    },
    {
        image: "/images/client/King-Koil.jpg"
    },
    
    {
        image: "/images/client/Techno.jpg"
    },
    {
        image: "/images/client/Saloni.jpg"
    },
    {
        image: "/images/client/True-Homes.jpg"
    },
    {
        image: "/images/client/SDHP.jpg"
    },
    {
        image: "/images/client/Helplab.jpg"
    },
    {
        image: "/images/client/IFFCO-Kisan.jpg"
    },
    {
        image: "/images/client/Red-cliff.jpg"
    },
    {
        image: "/images/client/KW-Group.jpg"
    },
    {
        image: "/images/client/Vanesa.jpg"
    },
    {
        image: "/images/client/Kailash-Hospital.jpg"
    },
    {
        image: "/images/client/Kailash-Deepak-Hospital.jpg"
    },
    {
        image: "/images/client/AAFT.jpg"
    },
    {
        image: "/images/client/Saviour.jpg"
    },
    {
        image: "/images/client/Crysta-IVF.jpg"
    },
    {
        image: "/images/client/Ektta.jpg"
    },
    {
        image: "/images/client/Amo.jpg"
    },
    {
        image: "/images/client/Aecc.jpg"
    },
    {
        image: "/images/client/NIU.jpg"
    },
    {
        image: "/images/client/Walnut.jpg"
    },
    {
        image: "/images/client/Oasis.jpg"
    },
    {
        image: "/images/client/Bhutan.png"
    },
    {
        image: "/images/client/Incenza.jpg"
    },
    {
        image: "/images/client/Mauri-Herbal.png"
    },
    {
        image: "/images/client/Uniexpert.jpg"
    },
    {
        image: "/images/client/Bakson.jpg"
    },
    {
        image: "/images/client/Bhagwant.jpg"
    },
    {
        image: "/images/client/kutbi.jpg"
    },
    {
        image: "/images/client/TMU-University.png"
    },
    {
        image: "/images/client/KSP-Pump.png"
    },
    {
        image: "/images/client/Dav-Sarla.png"
    },
    {
        image: "/images/client/Erisha.png"
    },
    {
        image: "/images/client/Pravek.png"
    },
    {
        image: "/images/client/FDDI.jpg"
    },
    {
        image: "/images/client/ICCR.png"
    },
    {
        image: "/images/client/Ammy.jpg"
    },
    {
        image: "/images/client/oyster.webp"
    },
    {
        image: "/images/client/ATS.png"
    },
    
]


const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-lg-3 col-6" key={index}>
                    <div className="brand-grid">
                        <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
                    </div>
                </div>
            ))}
        </>
    )
}

export default BrandItem;