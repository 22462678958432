import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/radio'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import AboutThree from '../counterup/AboutThree'
import { Helmet } from 'react-helmet';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div>
        <Helmet>
          <title>Radio Advertising | GC Media – Your Expert Radio Advertising Agency</title>
          <meta name="description" content="GC Media is a leading agency in radio advertising, offering creative and effective campaigns tailored to your brand's needs. Connect with your audience today." />
        </Helmet>
      </div>
      <PageHeading 
        title='Radio'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='Radio'
      />
      <Spacing lg='50' md='50'/>
      {/* End FunFact Section */}
       <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/Radio.webp" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45'/>
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">Radio</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Radio advertising is a process of developing and executing effective advertising campaigns on radio stations that reach and engage with the target audience. Effective radio advertising is a  deep understanding of the target audience and their listening habits, as well as a creative and strategic approach to developing impactful advertising campaigns.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">At our advertising agency, We specialize in developing customized and creative radio advertising campaigns that resonate with the target audience in a memorable and impactful way and help to achieve our client's advertising objectives.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We make a long-term commitment to building a strong brand identity and reputation, and a willingness to continuously adapt and evolve to changing market trends and listeners’ preferences. Contact us today to learn more about our radio advertising services and how we can help you achieve your business goals through effective advertising campaigns.</p>
              </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='100' md='45'/>
      <AboutThree/>
      

      {/* Start Service Section */}
      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s' 
                subtitle=''
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
