import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/dooh'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import { Helmet } from 'react-helmet';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div>
        <Helmet>
          <title>DOOH Advertising – Your Premier DOOH Advertising Agency</title>
          <meta name="description" content="Specializing in DOOH (Digital Out of Home) advertising, we offer innovative and targeted solutions to reach your audience effectively. Elevate your brand now." />
        </Helmet>
      </div>
      <PageHeading
        title='DOOH'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='DOOH'
      />

      <Spacing lg='80' md='50' />
      {/* End FunFact Section */}
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/Dooh.webp" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">Digital Out-of-Home (DOOH)</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_45 cs-height_lg_25" />
              <p className="cs-m0 clr-blk">In recent years, Digital Out-of-Home (DOOH) advertising has gained significant traction, revolutionizing the way we interact with our audiences. By merging the traditional with the digital, DOOH enables us to create immersive, engaging, and memorable ad experiences that capture the attention of consumers like never before.
              </p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Our team of seasoned strategists works closely with clients to develop comprehensive DOOH strategies that align with their overall marketing objectives. We carefully assess the target audience, campaign goals, and budget to create tailored solutions that deliver maximum impact.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">As a forward-thinking advertising agency, our expertise in DOOH is a key differentiator that sets us apart from our competitors. Our commitment to innovation, combined with our deep understanding of the DOOH landscape, enables us to deliver highly effective campaigns that drive results for our clients. Contact us today to learn more about our radio advertising services and how we can help you achieve your business goals through effective advertising campaigns.</p>
              <Div className="cs-height_45 cs-height_lg_30" />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* Start Service Section */}
      <Spacing lg='150' md='80' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
