import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'How is OTT different from traditional TV advertising?',
      answer: 'OTT (Over-the-Top) advertising refers to ads delivered through streaming platforms and services, such as Netflix, Hulu, Amazon Prime Video, and YouTube TV. Unlike traditional TV advertising, which broadcasts commercials to a wide audience during specific time slots, OTT advertising leverages advanced targeting capabilities and analytics to reach specific audience segments based on their viewing habits and demographic information, ensuring higher engagement and ROI.'
    },
    {
      question: 'How can my brand benefit from advertising on OTT platforms?',
      answer: 'Advertising on OTT platforms can help your brand reach a highly engaged and targeted audience. As consumers shift towards streaming platforms for content consumption, OTT advertising offers an opportunity to reach cord-cutters and cord-nevers who are no longer watching traditional TV. Additionally, advanced targeting capabilities and real-time analytics help in refining your marketing strategy and measuring campaign effectiveness.'
    },
    {
      question: 'What are some popular OTT platforms to advertise on?',
      answer: 'Popular OTT platforms for advertising include Hulu, Sony liv, Zee 5, Prime Video, Voot, MX Player, and Jio Cinema, among others. Each platform has its unique user base, targeting capabilities, and ad formats, allowing advertisers to select the best platform(s) based on their target audience and campaign goals. '
    },
    {
      question: 'What ad formats are available for OTT advertising?',
      answer: 'Common OTT ad formats include pre-roll, mid-roll, and post-roll video ads, which are similar to traditional TV commercials but are typically shorter, ranging from 15 to 30 seconds. Other formats include display ads, interactive ads, and sponsored content, which can be integrated within the streaming platform or content to provide a more immersive and engaging experience for viewers.'
    },
    {
      question: 'How do I measure the success of my OTT advertising campaigns?',
      answer: 'Key performance indicators (KPIs) for measuring OTT advertising campaign success include impressions, viewability, video completion rate, click-through rate, and conversions. Advanced analytics and attribution tools provided by OTT platforms help track user behavior and engagement, allowing advertisers to refine their campaigns and optimize for better results. '
    },
    {
      question: 'Can I target specific audience segments with OTT advertising?',
      answer: 'Yes, OTT advertising platforms offer advanced targeting capabilities, allowing us to target specific audience segments based on factors such as age, gender, location, interests, and viewing habits. This ensures your ads are shown to a relevant and engaged audience, resulting in higher conversion rates and ROI.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
