import React from 'react';
import SectionTitle from '../elements/section-title/SectionTitle';



const Datas = [
    {
        //id: 1,
        title: "Boost Brand Awareness",
        para: "Influencer marketing allows brands to tap into the influencers' existing audience, increasing brand visibility among relevant and engaged consumers. For instance, Daniel Wellington's partnership with top Indian influencers like Ayushmann Khurrana and Radhika Apte helped the watch brand build brand awareness and establish a strong presence in the Indian market."
    },
    {
        //id: 2,
        title: "Enhance Credibility",
        para: "Influencers are often viewed as experts in their respective niches. Collaborating with them lends credibility to your brand and makes your products or services more appealing to potential customers. For example, Nykaa's collaboration with beauty influencers like Malvika Sitlani and Komal Pandey has helped them establish trust and authority in the beauty and wellness sector."
    },
    {
        //id: 3,
        title: "Drive Engagement and Conversions",
        para: "Influencers have a strong connection with their audience, which leads to higher engagement rates. This translates into increased website traffic, social media followers, and ultimately, sales. Case in point: Samsung India's #PowerUpIndia campaign with tech influencers like Geekyranjit and Technical Guruji generated massive engagement and led to an increase in pre-orders for the Samsung Galaxy Note10."
    },
    {
        //id: 4,
        title: "Targeted Marketing",
        para: "Influencer marketing enables brands to reach a specific demographic or target audience, ensuring that marketing efforts are focused and effective. For example, the collaboration between Zomato and popular food influencers like Karan Dua (Dilsefoodie) and Mehak Dhawan (Foodiesince96) effectively targeted food enthusiasts across India."

    },
    {
        //id: 5,
        title: "Cost-Effective Strategy",
        para: "Compared to traditional advertising methods, influencer marketing can be a more cost-effective way to reach your target audience. By collaborating with micro or nano influencers, brands can achieve high engagement rates without spending a fortune on celebrity endorsements Our ad agency's role in successful influencer marketing campaigns",
    }
]

const AboutFour = () => {
    return (
        <div className="section section-padding bg-color pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    title="Unlock the Potential of Influencer Marketing in India – A Powerful Strategy for Brand Success"
                    //subtitle=": Explore how radio advertising continues to be a powerful medium in India, offering unmatched benefits to advertisers. Learn how our ad agency can help you tap into its potential!"
                   // description="Discover the value of influencer marketing in India, its benefits, and success stories. Learn how our ad agency can help your brand connect with the right influencers and maximize your marketing impact."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                {/* <h3 className="sl-number">{data.id}</h3> */}
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default AboutFour;