import { Icon } from '@iconify/react';
import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Team from '../Team';

export default function TeamSlider() {
  /** Team Member Data **/
  const teamData = [
    {
       memberImage: '/images/client/Sonalika.jpg',
      memberSocial: {
      
      },
    },
    
    {
      memberImage: '/images/client/VRL.jpg',
      memberSocial: {
       
      },
    },
    {
      memberImage: '/images/client/Satmola.jpg',
      memberSocial: {
  
      },
    },
    {
      memberImage: '/images/client/Nilkamal.jpg',
      memberSocial: {
      
      },
    },
    {
      memberImage: '/images/client/Today-Tea.jpg',
      memberSocial: {
      
      },
    },
    {
      memberImage: '/images/client/Cremica.png',
      memberSocial: {
      
      },
    },
    {
      memberImage: '/images/client/Body-Care.jpg',
      memberSocial: {
      
      },
    },
    {
      memberImage: '/images/client/GCL.jpg',
      memberSocial: {
      
      },
    },
    {
      memberImage: '/images/client/oyster.webp',
      memberSocial: {
      
      },
    },
    {
      memberImage: '/images/client/Indra-Ivf.jpg',
      memberSocial: {
      
      },
    },
    {
      memberImage: '/images/client/idp.jpg',
      memberSocial: {
      
      },
      
    },
    {
      memberImage: '/images/client/RSPL.jpg',
      memberSocial: {
      
      },
      
    },
  ];
  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    autoplay: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24 cs-arrow_style2">
      {teamData.map((item, index) => (
        <Div key={index}>
          <Team
            memberImage={item.memberImage}
            memberName={item.memberName}
            memberDesignation={item.memberDesignation}
            memberSocial={item.memberSocial}
          />
        </Div>
      ))}
    </Slider>
  );
}

