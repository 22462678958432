import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact2 from '../FunFact/FunFact2';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import ShowcasePortfolioHome from './ShowcasePortfolioHome';
import PostSlider from '../Slider/PostSlider';
import PostDlider from '../Slider/PostDlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';
import ServiceList from '../ServiceList';
import PortfolioSlider2 from '../Slider/PortfolioSlider2';
import ClientSlider from '../Slider/ClientSlider';
import ClientPatner from '../Slider/ClientPatner';
import { Helmet } from 'react-helmet';




export default function Home() {
  pageTitle('Home');

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Facbook',
      links: '/',
    },
    {
      name: 'Instagram',
      links: '/',
    },
    {
      name: 'Twitter',
      links: '/',
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: 'Years in Business',
      factNumber: '30+',
    },
    {
      title: 'Campaign Delivered',
      factNumber: '25K+',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <Helmet>
          <title>Gc Media Integrated Advertising Agency In India</title>
          <meta name="description" content="GC Media is a full-service integrated advertising agency offering Digital, TV, Print OTT,OOH, DOOH & Radio media solutions. Elevate your brand with our strategic and creative approach." />
        </Helmet>
      </div>
      {/* Start Hero Section */}
      <ShowcasePortfolioHome />

      {/* End Hero Section */}

      {/* Start Funfact Section */}
      <Div className="container">
        <FunFact2
          data={funfaceData}
          variant="cs-type1"
          bgUrl="/images/funfact_shape_bg.svg"
        />
      </Div>
      {/* End Funfact Section */}
      <Spacing lg="60" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/Intoduction.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-6 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Extensive experience in Advertising"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0 clr-blk">
                GC Media- Trust and Transparency is a  synergy of professionals providing top-notch advertising services across a variety of verticals. Since our founding in 1997, our agency has been committed to gauging strategic approaches to each project and working closely with our clients to understand their unique needs and tailor our services to meet their specific goals. At our core, believing that creativity, innovation, and collaboration are key to delivering successful campaigns. Over the years, our proven track record of success has made us a trusted partner for many of our clients, and we continue to strive for excellence in everything we do.
              </p>
              <Spacing lg="10" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="-18" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* Start Service Section */}
      <Spacing lg="60" md="80" />
      <Div id="service" className="bg-redlight">
        <Spacing lg='30' md='50' />
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="What Can We Do"
                btnText="See All Services"
                btnLink=""
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className='col-lg-4 col-sm-6'>
                  <Card
                    title='Strategy Planning'
                    link='/strategy-planning'
                    src='/images/services.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>

                <Div className='col-lg-4 col-sm-6'>
                  <Card
                    title='Media Buying'
                    link='/media-buying'
                    src='/images/services.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-4 col-sm-6'>
                  <Card
                    title='Brand Building'
                    link='/brand-building'
                    src='/images/services.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>


                <Div className='col-lg-4 col-sm-6'>
                  <Card
                    title='TV Ads'
                    link='/tv'
                    src='/images/services.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-4 col-sm-6'>
                  <Card
                    title='Digital'
                    link='/digital'
                    src='/images/services.webp'
                    alt='Service'
                  />
                  <Spacing lg='10' md='50' />
                </Div>
                <Div className='col-lg-4 col-sm-6'>
                  <Card
                    title='Print'
                    link='/print'
                    src='/images/services.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-4 col-sm-6'>
                  <Card
                    title='Radio'
                    link='/radio'
                    src='/images/services.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-4 col-sm-6'>
                  <Card
                    title='OOH'
                    link='/ooh'
                    src='/images/services.webp'
                    alt='Service'
                  />
                  <Spacing lg='10' md='50' />
                </Div>
                <Div className='col-lg-4 col-sm-6'>
                  <Card
                    title='DOOH'
                    link='/dooh'
                    src='/images/services.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}
      {/* Start MovingText Section */}
      <Div className="bg-redlight">
        <MovingText text="| CREATIVITY | INNOVATION | INSPIRATION | AWARENESS | EXPERIENCE" />
        <Spacing lg='30' md='50' />
      </Div>
      {/* End MovingText Section */}

      {/* End Services Section */}
      <Div className="cs-shape_wrap_4">
        {/* <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div> */}
        {/* <Div className="container">
          <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4 ">
            Our privilege of working with clients across a wide range of <span>industries</span>, <span>Automobile</span>, <span>Healthcare</span>, <span>Real-estate</span>, <span>E-commerce</span>, <span>Lifestyle</span>, <span>FMCG</span>, <span>BFSI</span>
            &nbsp;and Technology to <span>fashion</span> & <span>entertainment</span>.
          </h2>
        </Div> */}
      </Div>
      <Div className="cs-shape_wrap_4 bg-black pt-5 pb-5">
        <Spacing lg='30' md='50' />
        {/* <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div> */}
        <Div className="container">
          <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4 ">
            Our privilege of working with clients across a wide range of industries
          </h2>
          <Div className="row indus pt-3 pb-3">
            <Div className="col-lg-2 col-sm-4 industry">
              <h4>FMCG</h4>
            </Div>
            <Div className="col-lg-2 col-sm-4 industry">
              <h4>Automobile</h4>
            </Div>
            <Div className="col-lg-2 col-sm-4 industry">
              <h4>Healthcare</h4>
            </Div>
            <Div className="col-lg-2 col-sm-4 industry">
              <h4>Real-estate</h4>
            </Div>
            <Div className="col-lg-2 col-sm-4 industry">
              <h4>Education</h4>
            </Div>
          </Div>
          <Div className="row indus pb-3">
            <Div className="col-lg-2 industry">
              <h4>Hpme Care</h4>
            </Div>
            <Div className="col-lg-2 industry">
              <h4>BFSI</h4>
            </Div>
            <Div className="col-lg-2 industry">
              <h4>Entertainment</h4>
            </Div>
            <Div className="col-lg-2 industry">
              <h4>Fashion</h4>
            </Div>
            <Div className="col-lg-2 industry">
              <h4>Technology</h4>
            </Div>
          </Div>
          <Div className="row indus pb-3">
            <Div className="col-lg-2 ">
            </Div>
            <Div className="col-lg-2 industry">
              <h4>E-commerce</h4>
            </Div>
            <Div className="col-lg-2 industry">
              <h4>Lifestyle</h4>
            </Div>
            <Div className="col-lg-2 industry">
              <h4>PSU/Govt.</h4>
            </Div>
            <Div className="col-lg-2 ">
            </Div>
          </Div>
          <Spacing lg='30' md='50' />
        </Div>
      </Div>
      {/* Start Awards Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Our Solution
                For Any Every Business"
                subtitle=""
                variant="cs-style1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Awards Section */}

      {/* Start Video Block Section */}
      {/* <Spacing lg="140" md="70" />
      <Div className="bg-black">
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4 clr-red">
          Our agile process is ability to adapt and respond to change
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
          bgUrl="/images/video_bg.jpeg"
        />
      </Div>
      </Div> */}
      {/* End Video Block Section */}

      {/* Start Team Section */}
      {/* <Spacing lg="50" md="80" />
      <Div className="container">
        <SectionHeading
          title="Our Media <br/>Partners"
          subtitle=""
          variant="cs-style1"
        />
        <Spacing lg="50" md="45" />
        <ClientPatner />
      </Div> */}

      {/* End Team Section */}
      <Spacing lg="60" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Portfolio to explore"
            subtitle=""
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider />
      </Div>
      {/* Start Testimonial Section */}
      {/* <Spacing lg="40" md="45" />
      <TestimonialSlider /> */}
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      <Spacing lg="50" md="80" />
      <Div className="container">
        <SectionHeading
          title="Our Clients"
          subtitle=""
          variant="cs-style1 text-center"
        />
        <Spacing lg="50" md="45" />
        <ClientSlider />
      </Div>
      {/* <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Our Media Partners"
                subtitle=""
                btnText="View More Blog"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Blog Section */}
      {/* Start Team Section */}

      {/* <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Our team is committed to achieving the goals of our clients by delivering innovative, effective solutions."
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div> */}
      {/* End Team Section */}
      <Spacing lg="70" md="30" />


      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  );
}
