import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
// import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import ServiceDetailsPage from './components/Pages/ServiceDetailsPage';
import ServicesPage from './components/Pages/ServicesPage';
import TeamPage from './components/Pages/TeamPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import TeamDetails from './components/Pages/TeamDetails';
import PhotographyAgencyHome from './components/Pages/PhotographyAgencyHome';
import CreativePortfolioHome from './components/Pages/CreativePortfolioHome';
import DigitalAgencyHome from './components/Pages/DigitalAgencyHome';
import MarketingAgencyHome from './components/Pages/MarketingAgencyHome';
import ShowcasePortfolioHome from './components/Pages/ShowcasePortfolioHome';
import CaseStudyShowcaseHome from './components/Pages/CaseStudyShowcaseHome';
import Layout from './components/Layout';
import CaseStudyDetailsPage from './components/Pages/CaseStudyDetailsPage';
import FaqPage from './components/Pages/FaqPage';
import DigitalHome from './components/Pages/DigitalHome';
import ServiceDevelopment from './components/Pages/ServiceDevelopment';
import CounterUpTwo from './components/counterup/CounterUpTwo';
import ServiceTwo from './components/service2/ServiceTwo';
import StrategyPlanning from './components/Pages/StrategyPlanning';
import MediaBuying from './components/Pages/MediaBuying';
import BrandBuilding from './components/Pages/BrandBuilding';
import PrintMedia from './components/Pages/PrintMedia';
import OutofHome from './components/Pages/OutofHome';
import Dooh from './components/Pages/Dooh';
import TvAd from './components/Pages/TvAd';
import ConnectedTv from './components/Pages/ConnectedTv';
import TvcProduction from './components/Pages/TvcProduction';
import OvertheTop from './components/Pages/OvertheTop';
import PayPerClick from './components/Pages/PayPerClick';
import AboutThree from './components/counterup/AboutThree';
import RadioAd from './components/Pages/RadioAd';
import InfluencerMarketing from './components/Pages/InfluencerMarketing';
import AboutFour from './components/counterup/AboutFour';
import AboutSocial from './components/counterup/AboutSocial';
import SocialMedia from './components/Pages/SocialMedia';
import DynamicWebsite from './components/Pages/DynamicWebsite';
import ClientGc from './components/Pages/ClientGc';
import TypographyPr from './components/counterup/TypographyPr';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import ProcessOne from './components/process/ProcessOne';
import WebsiteDevlopment from './components/Pages/WebsiteDevlopement';
import SmartTv from './components/Pages/SmartTv';
import LocationTab from './components/tab/LocationTab';
import CaseStudy from './components/Pages/CaseStudy';





function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="photography-agency"
            element={<PhotographyAgencyHome />}
          />
          <Route path="digital-agency" element={<DigitalAgencyHome />} />
          <Route path="marketing-agency" element={<MarketingAgencyHome />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="service" element={<ServicesPage />} />
          <Route path="/digital" element={<DigitalHome />} />
          <Route path="servicedevelopment" element={<ServiceDevelopment />} />
          <Route path="/strategy-planning" element={<StrategyPlanning/>} />
          <Route path='media-buying' element={<MediaBuying/>} />
          <Route path='brand-building' element={<BrandBuilding/>} />
          <Route path="/print" element={<PrintMedia/>} />
          <Route path="/ooh" element={<OutofHome/>} />
          <Route path="/tv" element={<TvAd/>}/>
          <Route path="ConnectedTv" element={<ConnectedTv/>}/>
          <Route path="/tvc" element={<TvcProduction/>}/>
          <Route path="/ott" element={<OvertheTop/>}/>
          <Route path="/ppc" element={<PayPerClick/>}/>
          <Route path="/radio" element={<RadioAd/>}/>
          <Route path="/influencer-marketing" element={<InfluencerMarketing/>}/>
          <Route path="/social" element={<SocialMedia/>}/>
          <Route path="DynamicWebsite" element={<DynamicWebsite/>}/>
          <Route path="/clients" element={<ClientGc/>}/>
          <Route path="privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path='/website-devlopment' element={<WebsiteDevlopment/>}/>
          <Route path='/smart-tv' element={<SmartTv/>}/>
          <Route path='/dooh' element={<Dooh/>}/>
          <Route path='LocationTab' element={<LocationTab/>}/>
          <Route path='/case-study' element={<CaseStudy/>}/>
          <Route
            path="service/:serviceDetailsId"
            element={<ServiceDetailsPage />}
          />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio/:portfolioDetailsId"
            element={<PortfolioDetailsPage />}
          />
          {/* <Route path="contact" element={<ContactPage />} /> */}
          <Route path="team" element={<TeamPage />} />
          <Route path="page1" element={<CounterUpTwo />} />
          <Route path="AboutThree" element={<AboutThree />} />
          <Route path="AboutFour" element={<AboutFour/>} />
          <Route path="AboutSocial" element={<AboutSocial/>} />
          <Route path="TypographyPr" element={<TypographyPr/>} />
          <Route path="ProcessOne" element={<ProcessOne/>}/>
          <Route path="page2" element={<ServiceTwo />} />
          <Route path="team/:teamDetails" element={<TeamDetails />} />
          <Route
            path="/case-study/:caseStudyDetailsId"
            element={<CaseStudyDetailsPage />}
          />
          <Route path="faq" element={<FaqPage />} />
          
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
          <Route
            path="creative-portfolio"
            element={<CreativePortfolioHome />}
          />
          <Route
            path="showcase-portfolio"
            element={<ShowcasePortfolioHome />}
          />
          <Route
            path="case-study-showcase"
            element={<CaseStudyShowcaseHome />}
          />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
