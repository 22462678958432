import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What social media platforms do you recommend for our business to advertise on?',
      answer: 'We recommend advertising on platforms such as Facebook, Instagram, LinkedIn, and Twitter depending on your target audience and business goals. We"ll analyze your specific needs and help you choose the most effective platforms for your brand.'
    },
    {
      question: 'How much should we budget for social media advertising?',
      answer: 'Budgeting for social media advertising depends on factors like your target audience, campaign objectives, and desired reach. We can help you determine an appropriate budget by evaluating your goals and providing a customized plan based on your specific needs.'
    },
    {
      question: 'Can you help us create content for our social media ads?',
      answer: 'Yes, our team of skilled content creators can help you develop eye-catching and engaging content for your social media ads, including images, videos, and copywriting, tailored to your brands voice and message.'
    },
    {
      question: 'How can we target specific demographics with our social media ads?',
      answer: 'Most social media platforms offer advanced targeting options, allowing you to focus your ads on specific demographics, interests, and behaviors. We can help you identify the ideal target audience and set up the targeting parameters to reach them effectively.'
    },
    {
      question: 'How long does it take to see results from a social media advertising campaign?',
      answer: 'The time frame for results can vary depending on the platform, ad type, and campaign objectives. Typically, you may start seeing initial results within a few days to a couple of weeks. However, we recommend running campaigns for at least a month to gather enough data for meaningful analysis and optimization. '
    },
    {
      question: 'Can you manage our entire social media presence, not just advertising?',
      answer: 'Yes, we offer comprehensive social media management services, including content creation, scheduling, engagement, and monitoring. We can create a cohesive social media strategy to help you build and maintain a strong brand presence across your chosen platforms.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
