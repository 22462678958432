import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import ContactLocationNew from '../contact/ContactLocationNew';
import SectionTitle from '../section-title/SectionTitleNew';
import BrandOne from '../brand/BrandOne';
import { Helmet } from 'react-helmet';


export default function Client() {
  pageTitle('Client');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <Helmet>
          <title>Client Projects | Transforming Businesses Through Advertisement</title>
          <meta name="description" content="Explore our client list at GC Media to see how we provide top-notch solutions in Advertising.Your success is our priority." />
        </Helmet>
      </div>
      <PageHeading
        title="Client"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="90" md="45" />

      <BrandOne />
      <Spacing lg="90" md="45" />
    </>
  );
}
