import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/web'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import ServicePropOne from '../service/ServicePropOne'
import SectionTitle from '../section-title/SectionTitleNew'
import SocialWidget from '../Widget/SocialWidget'
import AboutSocial from '../counterup/AboutSocial'
import { Helmet } from 'react-helmet';


export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div>
        <Helmet>
          <title>Website Development | GC Media – Top Website Development Agency</title>
          <meta name="description" content="GC Media offers exceptional Website Development Services tailored to your business needs. From design to deployment, we are your one-stop development agency for Shopify, Wordpress, HTML websites." />
        </Helmet>
      </div>
      <PageHeading 
        title='Website Development'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='Website Development'
      />
      <Spacing lg='50' md='50'/>
      {/* End FunFact Section */}
       <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/website.webp" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45'/>
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">Website Development</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
          
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Web development demands creating and maintaining websites that meet the specific needs of clients and their target audiences. This can include designing websites that are visually appealing, easy to navigate, and optimized for search engines.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We have expertise in developing websites (Shopify, Wordpress, wix, html) that are user-friendly, visually appealing, and optimized for search engines. We maintain standards by implementing measures such as SSL certificates, firewalls, and privacy policies to protect user data and maintain the trust of our clients. We are determined to create custom designs and layouts, develop content strategies, and integrate features such as e-commerce functionality, contact forms, and social media feeds.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">By creating compelling and engaging websites, We help our clients to establish a strong online presence, build brand awareness, and ultimately drive conversions and sales. Contact us today to learn more about our services and how we can help you achieve your business goals by creating a powerful website.</p>
              </Div>
          </Div>
        </Div>
      </Div>
      {/* <div className="section section-padding bg-color-light">
                <div className="container">
                    <SectionTitle 
                        subtitle="What We Can Do For You"
                        title="Services we can <br> help you with"
                        description=""
                        textAlignment="heading-left"
                        textColor=""
                    />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" itemShow="6" marginTop="no" />
                    </div>
                </div>
            </div> */}

      {/* Start Service Section */}
     

      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s' 
                subtitle=''
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
