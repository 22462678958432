import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import Card from '../Card';
import Div from '../Div';
import Accordion from '../Accordion/digital'
import FunFact2 from '../FunFact/FunFact2';
import MasonryGallery from '../Gallery/MasonryGallery';
import Hero2 from '../Hero/Hero2';
import PricingTableList from '../PricingTable/PricingTableList';
import SectionHeading from '../SectionHeading';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import Spacing from '../Spacing';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import { Helmet } from 'react-helmet';


export default function DigitalHome() {
  pageTitle('Digital Agency');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroData = [
    {
      title: 'OTT Ads',
      imageUrl: './images/OTT1.png',
      href: '/ott',
    },
    {
      title: 'Smart TV ads',
      imageUrl: './images/Smart-Tv.png',
      href: '/smart-tv',
    },
    {
      title: 'Development',
      imageUrl: './images/Website-Development.png',
      href: '/website-devlopment',
    },
    {
      title: 'Social Media',
      imageUrl: './images/Social-Media.png',
      href: '/social',
    },
    {
      title: 'Pay Per Click',
      imageUrl: './images/pay-per-click.png',
      href: '/ppc',
    },
    {
      title: 'Influencers',
      imageUrl: './images/influencer.png',
      href: '/influencer-marketing',
    },
  ];
  const funfaceData = [
    {
      title: 'Products',
      factNumber: '550',
    },
    {
      title: 'Global happy clients',
      factNumber: '40K',
    },
    {
      title: 'Project completed',
      factNumber: '50k',
    },
    {
      title: 'Team members',
      factNumber: '250',
    },
  ];
  return (
    <>
      <div>
        <Helmet>
          <title>Digital Media Services | GC Media – Elevate Your Brand Online</title>
          <meta name="description" content="GC Media specializes in digital media solutions to enhance your brand's online presence. From strategy to execution, discover how we drive digital success." />
        </Helmet>
      </div>
      {/* Start Hero Section */}
      <Hero2 data={heroData} />
      {/* End Hero Section */}

      {/* Start About Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row align-items-center cs-column_reverse_lg">
          <Div className="col-lg-5">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src="/images/Digital Marketing.webp"
                alt="About"
                className="cs-w100 cs-radius_5"
              />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-lg-1">
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">Digital Marketing</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_40 cs-height_lg_25" />
              <p className="cs-m0 clr-blk">Digital advertising encompasses a variety of components, including display advertising, social media advertising, search engine marketing, video advertising, and more. Each of these components serves a unique purpose and can be used to target specific audiences and achieve specific business objectives.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Our team of experienced designers, strategists, and copywriters work together to create impactful digital advertising campaigns that capture attention and drive engagement. We stay up-to-date with the latest trends and best practices in the industry to ensure that our clients are always ahead of the competition.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">As a leading advertising agency in India, we understand the importance of digital advertising as it has become an increasingly important component of modern marketing strategies, and if you are looking to take your business to the next level with innovative and effective digital advertising, our agency is here to help. Contact us today to learn more about our services and how we can help you achieve your business goals through powerful digital advertising campaigns.</p>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End About Section */}

      {/* Start Contact Section */}
      <Spacing lg="60" md="70" />
      <Div className="container">
      </Div>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='90' md='45' />
      {/* Start Contact Section */}
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  );
}
