import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/social'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import AboutSocial from '../counterup/AboutSocial'
import { Helmet } from 'react-helmet';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div>
        <Helmet>
          <title>Social Media Marketing | GC Media – Leading Social Media Agency</title>
          <meta name="description" content="Enhance your brand's online presence with GC Media's top-notch Social Media Marketing services. We craft strategies that engage and convert your target audience." />
        </Helmet>
      </div>
      <PageHeading
        title='Social Media'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='Social Media'
      />
      <Spacing lg='50' md='50' />
      {/* End FunFact Section */}
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/social Media.webp" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">Social Media</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />

              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Social media marketing is the practice of promoting a brand through social media platforms such as Facebook, Instagram, Twitter, LinkedIn, and others. Succeeding in social media marketing understanding the unique cultural, social, and economic factors that shape the consumer landscape. </p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">In addition, consumers are highly active on social media, with Facebook, Instagram, and WhatsApp being the most popular platforms. Our agency leverages an audience that is highly active on social media by creating engaging and interactive content that is shareable and helps to build brand awareness and loyalty.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We offer Social media marketing in a powerful way to reach and engage with consumers in a highly targeted and effective way, while also building brand awareness and loyalty. Contact us today to learn more about our services and how we can help you achieve your business goals through powerful Social Media advertising.</p>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='100' md='45' />
      {/* <AboutSocial/> */}

      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
