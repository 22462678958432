import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/smarttv'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import AboutFour from '../counterup/AboutFour'
import { Helmet } from 'react-helmet';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div>
        <Helmet>
          <title>Smart TV Advertising | GC Media – Your Trusted Smart TV Advertisement</title>
          <meta name="description" content="Specialize in Smart TV advertising with GC Media. We offer targeted campaigns that reach your audience right on their Smart TVs. Take your brand to the next level." />
        </Helmet>
      </div>
      <PageHeading
        title='Smart TV'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='Smart TV'
      />
      <Spacing lg='50' md='50' />
      {/* End FunFact Section */}
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/Smart Tv Ads.webp" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">Smart TV</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Smart TV ads provide a new level of interactivity and engagement, as they are becoming increasingly popular among consumers, and have become an important avenue for reaching the target audiences which helps increase brand awareness, drive sales, and build customer loyalty.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We as advertising experts, specialize in smart TV ads creating custom ad formats such as interactive overlays, sponsored content, or even full-screen commercials that are seamlessly integrated with the user's viewing experience. We have expertise in creating and managing digital advertising campaigns that target audiences on smart TVs. </p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Overall, smart TV ads are a powerful tool for advertising agencies to help their clients reach and engage with their target audiences on a platform that is becoming increasingly popular and important for video consumption. By developing and executing effective smart TV ad campaigns, advertising agencies can help their clients to achieve their business objectives and stay ahead of the competition.</p>
            </Div>
          </Div>
        </Div>
      </Div>

      <Spacing lg='145' md='80' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
