import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What is pay-per-click (PPC) advertising, and how does it work?',
      answer: 'Pay-per-click (PPC) advertising is a digital marketing model where advertisers pay a fee each time their ad is clicked. Its a way of buying visits to your site, rather than attempting to earn those visits organically. In this model, ads are typically placed on search engine results pages (SERPs) or other websites, and advertisers bid on relevant keywords or placements to target their desired audience.'
    },
    {
      question: 'How do you set a budget for my PPC campaign?',
      answer: 'Setting a budget for your PPC campaign depends on factors such as your advertising goals, industry, target audience, and competition. We work with you to determine an appropriate budget that aligns with your business objectives while ensuring your ads remain cost-effective and deliver optimal results.'
    },
    {
      question: 'How do you measure the success of a PPC campaign?',
      answer: 'We track various key performance indicators (KPIs) to evaluate the success of your PPC campaign, including click-through rate (CTR), conversion rate, cost per click (CPC), cost per conversion, and return on ad spend (ROAS). These metrics help us monitor the effectiveness of your campaign and make data-driven adjustments to optimize performance.'
    },
    {
      question: 'Can you manage PPC campaigns on multiple platforms, such as Google Ads, Bing Ads, and social media?',
      answer: 'Yes, we are experienced in managing PPC campaigns across various platforms, including Google Ads, Bing Ads, and social media channels like Facebook, Instagram, and LinkedIn. We can create and optimize campaigns tailored to each platforms unique features and audience demographics to maximize your advertising reach and ROI.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
