import React from 'react';
import SectionTitle from '../elements/section-title/SectionTitle';


const Datas = [
    {
        title: "Facebook",
        para: "With over 320 million active users in India, Facebook offers an extensive reach and the ability to target specific demographics, making it a powerful advertising medium for businesses. From carousel ads to video ads, our ad agency utilizes Facebook's diverse ad formats to craft engaging campaigns that drive brand awareness, engagement, and conversions."
    },
    {
        title: "Instagram",
        para: "A visual-centric platform with over 120 million users in India, Instagram is ideal for showcasing your brand's aesthetic and personality. Our creative team develops eye-catching sponsored posts and stories, helping brands reach a younger, trend-savvy audience and drive website traffic, app installs, and e-commerce sales."
    },
    {
        title: "Twitter",
        para: "With its real-time, fast-paced nature, Twitter is an excellent platform for businesses to engage in conversations, share updates, and promote events. Our ad agency leverages Twitter's promoted tweets and trends to enhance brand visibility, drive website traffic, and generate leads among India's 22.2 million active users."
    },
    {
        title: "LinkedIn",
        para: "As the go-to platform for professionals, LinkedIn is an invaluable tool for B2B marketing and thought leadership. With over 71 million users in India, our ad agency crafts compelling sponsored content and InMail campaigns to help businesses build credibility, generate leads, and establish meaningful connections with industry professionals."

    },
    {
        title: "YouTube",
        para: "As the second most popular platform in India with over 325 million users, YouTube offers brands the opportunity to captivate their audience through engaging video content. Our team creates targeted pre-roll, mid-roll, and display ads that capture the viewer's attention and promote brand recall, driving leads and conversions."
    }
]

const AboutThree = () => {
    return (
        <div className="section section-padding bg-color pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    title="Amplify Your Brand's Presence with Social Media Advertising in India – Unleashing the Power of Digital Platforms"
                    //subtitle=": Explore how radio advertising continues to be a powerful medium in India, offering unmatched benefits to advertisers. Learn how our ad agency can help you tap into its potential!"
                    //description="Explore how radio advertising continues to be a powerful medium in India, offering unmatched benefits to advertisers. Learn how our ad agency can help you tap into its potential!"
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default AboutThree;