import React from 'react'
import Div from '../Div'
import { Icon } from '@iconify/react';
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'
import ContactInfoWidgetT from '../Widget/ContactInfoWidgetT'
import Spacing from '../Spacing'
import { Link } from 'react-router-dom'

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const copyrightLinks = [
    {
      title: 'Terms of Use',
      href: '/'
    },
    {
      title: 'Privacy Policy',
      href: '/'
    }
  ]

  const serviceMenu = [
    {
      title: 'Print',
      href: 'print'
    },
    {
      title: 'TV',
      href: 'tv'
    },
    {
      title: 'Radio',
      href: 'radio'
    },
    {
      title: 'OOH',
      href: 'ooh'
    },
    {
      title: 'DOOH',
      href: 'dooh'
    },
  ]
  const serviceMenu1 = [
    {
      title: 'OTT',
      href: 'ott'
    },
    {
      title: 'Social Media',
      href: 'social'
    },
    {
      title: 'Pay Per Click',
      href: 'ppc'
    },
    {
      title: 'Influencers Marketing',
      href: 'influencer-marketing'
    },
    {
      title: 'Web Development',
      href: 'website-devlopment'
    },
  ]
  const serviceMenu2 = [
    {
      title: 'Shopify',
      href: 'website-devlopment'
    },
    {
      title: 'Wordpress',
      href: 'website-devlopment'
    },
    {
      title: 'Magento',
      href: 'website-devlopment'
    },
    {
      title: 'Wix',
      href: 'website-devlopment'
    },
    {
      title: 'Custom Development',
      href: 'website-devlopment'
    },
  ]

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc='/images/logo1.png'
                  logoAlt='GC Media Pvt Ltd'
                  text='We welcome our clients with utmost companionship'
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-2 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading='Linear Media' />
              </Div>
            </Div>
            <Div className="col-lg-2 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu1} menuHeading='Online' />
              </Div>
            </Div>
            <Div className="col-lg-2 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu2} menuHeading='E-commerce Solution' />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Head Office' />
                <Spacing lg="30" md="70" />
                <ContactInfoWidgetT title1="Branch offices" />
              </Div>
            </Div>

          </Div>
        </Div>
      </Div>
      <Div className="bg-black">
        <Div className="container">
          <Div className="cs-bottom_footer">
            <Div className="cs-bottom_footer_left">
              <Div className="cs-copyright">Copyright © 2023 GC Media Pvt Ltd.</Div>
            </Div>
            <Div className="cs-bottom_footer_right">
              <MenuWidget menuItems={copyrightLinks} variant=' cs-style2' />
            </Div>
          </Div>
        </Div>
        {/* <Div className="cs-whatsapp">
          <Link to='https://api.whatsapp.com/send?phone=1234567890' target="_blank" rel="noopener noreferrer">
            <Icon icon="logos:whatsapp-icon" />
          </Link>
        </Div> */}
      </Div>
    </footer>
  )
}
