import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Connected TV '
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='50' md='50'/>
      {/* End FunFact Section */}
       <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/member_details_1.jpeg" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45'/>
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title">CTV</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_45 cs-height_lg_25" />
              <p className="cs-m0">We specialize in providing innovative and effective Connected TV (CTV) advertising solutions to clients throughout India. As a leading agency in the industry, we understand the importance of CTV advertising in reaching audiences in their homes and creating brand awareness that lasts.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0">With the rise of streaming services and smart TVs, CTV advertising has become an increasingly popular and effective way to reach consumers. In India, there are currently over 197 million CTV households, representing a significant and growing segment of the population. This presents a tremendous opportunity for businesses looking to reach their target audiences through innovative and impactful CTV advertising campaigns.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0">Our agency offers a wide range of CTV advertising services, designed to help our clients achieve their business goals. We work closely with clients to understand their unique needs and create customized campaigns that are tailored to their specific objectives. Whether it's increasing brand awareness, promoting a new product, or driving sales, our CTV advertising solutions are designed to achieve results.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0">We bring together a team of experienced professionals who are passionate about their work and dedicated to delivering exceptional results. Our designers, strategists, and copywriters work collaboratively to create impactful CTV advertising campaigns that capture attention and drive engagement. We stay up-to-date with the latest trends and best practices in the industry to ensure that our clients are always ahead of the competition.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0">At our agency, we believe that creativity, innovation, and attention to detail are the keys to delivering successful CTV advertising campaigns. We strive to create memorable and effective campaigns that resonate with our clients' target audiences, and we continuously monitor and optimize our campaigns to ensure maximum effectiveness.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0">If you are looking to take your business to the next level with innovative and effective CTV advertising, our agency is here to help.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <a class="cs-btn cs-style1 cs-type1" href="/case-study/case-study-details"><span>Contact us</span></a>

            </Div>
          </Div>
        </Div>
      </Div>
      {/* Start Service Section */}
      <Spacing lg='150' md='80'/>
      <TestimonialSlider />
      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
