import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What is the average cost of a TV advertisement?',
      answer: 'The average cost of a TV advertisement can vary significantly depending on factors such as the network, time slot, ad length, and production quality.'
    },
    {
      question: 'How can I measure the success of my TV advertisement?',
      answer: 'The success of a TV advertisement can be measured using various metrics, such as reach, frequency, impressions, and return on investment (ROI). Additionally, we can track online engagement, website traffic, and sales data to determine the effectiveness of your ad campaign.'
    },
    {
      question: 'Can I target a specific audience with my TV advertisement?',
      answer: 'Yes, we can target specific audiences with your TV advertisement by selecting the appropriate network, time slot, and programming that best aligns with your target demographic. Additionally, many cable and satellite providers offer addressable advertising, allowing you to deliver your ad to specific households based on demographic and behavioral data.'
    },
    {
      question: 'How can I make my TV advertisement stand out from the competition?',
      answer: 'To make your TV advertisement stand out, focus on creating a unique, memorable, and engaging concept that resonates with your target audience. High-quality production, strong visuals, and a clear call-to-action can also contribute to the effectiveness of your ad.'
    },
    {
      question: 'What types of TV advertisement formats are available?',
      answer: 'There are several TV advertisement formats to choose from, including live-action, animation, stop-motion, and a combination of these. The format you select should align with your brands identity,message and target audience preferences.'
    },
    {
      question: 'Can I integrate digital and social media elements into my TV advertising campaign?',
      answer: 'Yes, integrating digital and social media elements into your TV advertising campaign can help amplify your message and increase audience engagement.Cross-promoting your ad on social media platforms, using QR codes, and creating shareable content can help bridge the gap between traditional TV advertising and digital channels.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
