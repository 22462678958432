import React from 'react';
// import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SectionTitle from '../elements/section-title/SectionTitle';
import PageHeading from '../PageHeading';
import Spacing from '../Spacing';
import ServicePropOne from '../service/ServicePropOne';


const ServiceTwo = () => {


    return (
        <>
        {/* <SEO title="Service Two" /> */}

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            {/* <BcrumbBannerOne 
                title="Best solutions for your business"
                paragraph ="Give your business a unique logo to stand out from crowd. We’ll create logo specifically for your company.
                "
                styleClass=""
                mainThumb="/images/banner/banner-thumb-4.png"
            /> */}
            <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      <Spacing lg="150" md="80"/>
            <div className="section section-padding bg-color-light">
                <div className="container">
                    <SectionTitle 
                        subtitle="What We Can Do For You"
                        title="Services we can <br> help you with"
                        description=""
                        textAlignment="heading-left"
                        textColor=""
                    />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" itemShow="6" marginTop="no" />
                    </div>
                </div>
            </div>

            {/* <AboutThree /> */}
            
             

            {/* <CtaLayoutOne /> */}
        </main>
        </>
    )
}

export default ServiceTwo;