import { Icon } from '@iconify/react';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import React, { useState } from 'react';

export default function CodeTab() {
  const [activeTab, setActiveTab] = useState('Head Office'); // Initial active tab state

  // Function to handle tab change
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className='container'>
      <div className="tab-header">
        <button
          onClick={() => handleTabChange('Head Office')}
          className={activeTab === 'Head Office' ? 'active' : ''}
        >
          Noida
        </button>
        <button
          onClick={() => handleTabChange('Registered Office')}
          className={activeTab === 'Registered Office' ? 'active' : ''}
        >
          Delhi
        </button>
        <button
          onClick={() => handleTabChange('Lucknow')}
          className={activeTab === 'Lucknow' ? 'active' : ''}
        >
          Lucknow
        </button>
        <button
          onClick={() => handleTabChange('Jaipur')}
          className={activeTab === 'Jaipur' ? 'active' : ''}
        >
          Jaipur
        </button>
        <button
          onClick={() => handleTabChange('Mumbai')}
          className={activeTab === 'Mumbai' ? 'active' : ''}
        >
          Mumbai
        </button>
      </div>
      <Spacing lg="60" md="20" />
      <div className="tab-content">
        {activeTab === 'Head Office' && (
          <div className='container'>
            <div className="row tab-row">
              <div className="col-xl-6 col-lg-6">
                <div className="tab-info">
                  <p>Head Office Address</p>
                  <h4>First Floor, H-94 Sector 63, Gautam Buddh Nagar (UP) - 201301</h4>
                  <p>Email id</p>
                  <h5><a href="mailto:info@gcmedia.in">info@gcmedia.in</a></h5>
                  <p>Phone No.</p>
                  <h5><a href="">0120-4150110 | 4902734</a></h5>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="tab-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.1085467743624!2d77.3756588752255!3d28.626508684354693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfcad3ed041d1%3A0x9470bafc697a89c5!2sGC%20MEDIA%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1693656866098!5m2!1sen!2sin"
                    allowFullScreen
                    title="Google Map"
                    width="100%"
                    height="400px"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'Registered Office' && (
          <div className='container'>
            <div className="row tab-row">
              <div className="col-xl-6 col-lg-6">
                <div className="tab-info">
                  <p>Registered Office Address</p>
                  <h4>Office 1/16, 3rd Floor, Lalita Park, Vikas Marg, Nr. Metro Stn. Laxmi Nagar, Delhi-110092</h4>
                  <p>Email id</p>
                  <h5><a href="mailto:info@gcmedia.in">info@gcmedia.in</a></h5>
                  <p>Phone No.</p>
                  <h5><a href="">0120-4150110 | 4902734</a></h5>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="tab-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.1085467743624!2d77.3756588752255!3d28.626508684354693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfcad3ed041d1%3A0x9470bafc697a89c5!2sGC%20MEDIA%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1693656866098!5m2!1sen!2sin"
                    allowFullScreen
                    title="Google Map"
                    width="100%"
                    height="400px"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'Lucknow' && (
          <div className='container'>
            <div className="row tab-row">
              <div className="col-xl-6 col-lg-6">
                <div className="tab-info">
                  <p>Branch Office Address</p>
                  <h4>Office no. 08, 12th Floor, Wing B Summit Building, Vibhuti Khand, Gomti Nagar, Lucknow, Uttar Pradesh-226010 </h4>
                  <p>Email id</p>
                  <h5><a href="mailto:info@gcmedia.in">info@gcmedia.in</a></h5>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="tab-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.203828111936!2d81.0124586485208!3d26.86526446724321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be32a4ce642dd%3A0x7ad57610f25179f1!2sGC%20Media%20Pvt%20Ltd%20-%20Lucknow!5e0!3m2!1sen!2sin!4v1693811914214!5m2!1sen!2sin"
                    allowFullScreen
                    title="Google Map"
                    width="100%"
                    height="400px"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'Jaipur' && (
          <div className='container'>
            <div className="row tab-row">
              <div className="col-xl-6 col-lg-6">
                <div className="tab-info">
                  <p>Branch Office Address</p>
                  <h4>Office No.229, Second floor, Manglam Metropolis Tower, Near Purani Chungi, Ajmer Road,Jaipur, Rajasthan -302019</h4>
                  <p>Email id</p>
                  <h5><a href="mailto:info@gcmedia.in">info@gcmedia.in</a></h5>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="tab-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.2338838908486!2d75.75316407516775!3d26.896071460806088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5922f38bfef%3A0xe70a23a8146df534!2sGC%20MEDIA%20PVT%20LTD%20-%20JAIPUR!5e0!3m2!1sen!2sin!4v1693812042857!5m2!1sen!2sin"
                    allowFullScreen
                    title="Google Map"
                    width="100%"
                    height="400px"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'Mumbai' && (
          <div className='container'>
            <div className="row tab-row">
              <div className="col-xl-6 col-lg-6">
                <div className="tab-info">
                  <p>Branch Office Address</p>
                  <h4>Office No-03, Plot No. B-21, Sector-16 Road, Ground Floor, Ulave, Panvel, Navi Mumbai-410206</h4>
                  <p>Email id</p>
                  <h5><a href="mailto:info@gcmedia.in">info@gcmedia.in</a></h5>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="tab-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.1085467743624!2d77.3756588752255!3d28.626508684354693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfcad3ed041d1%3A0x9470bafc697a89c5!2sGC%20MEDIA%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1693656866098!5m2!1sen!2sin"
                    allowFullScreen
                    title="Google Map"
                    width="100%"
                    height="400px"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

