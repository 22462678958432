import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What is Strategic Planning?',
      answer: 'Strategic planning is the organizational process of defining a company`s direction and making decisions to allocate its resources in pursuit of its objectives. This includes capital, labor, and time, aimed at achieving long-term success. A strategic plan serves as a blueprint that guides the organization`s operations and future development.'
    },
    {
      question: 'Why is Strategic Planning Important for Businesses?',
      answer: 'Strategic planning is essential for businesses because it provides a framework for achieving long-term goals and sustaining growth. It helps organizations adapt to market changes, anticipate opportunities, and allocate resources more efficiently. Without a strategic plan, businesses may lack direction and focus, leading to wasted resources and missed opportunities.'
    },
    {
      question: 'How Often Should a Strategic Plan Be Updated?',
      answer: 'The frequency of updating a strategic plan can vary depending on the industry, market conditions, and internal organizational changes. However, it`s generally recommended to review and update the strategic plan at least annually. This allows businesses to adapt to market changes, assess the effectiveness of strategies, and make necessary adjustments.'
    },
    {
      question: 'What is SWOT Analysis in Strategic Planning?',
      answer: 'SWOT analysis is a strategic planning tool used to evaluate the Strengths, Weaknesses, Opportunities, and Threats related to a business or a specific project. Strengths and Weaknesses are internal factors, whereas Opportunities and Threats are external factors. The analysis helps organizations identify areas for improvement and devise strategies to leverage opportunities and mitigate threats.'
    },
    {
      question: 'How Do You Implement a Strategic Plan?',
      answer: 'Implementing a strategic plan involves several steps: Communication: Clearly convey the strategic plan to all members of the organization.Resource Allocation: Allocate necessary resources, such as personnel, budget, and time, to meet the objectives.Action Plan: Develop a detailed action plan with timelines and responsibilities.Monitoring: Track progress using Key Performance Indicators (KPIs).Adjustments: Make real-time adjustments to the plan as needed, based on performance and changing circumstances.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
