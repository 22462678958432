import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'How effective is print media in today"s digital age?',
      answer: 'Even in our digital age, print media remains a trusted source for many consumers. Its tangible nature can create a deeper connection, and research shows it often enjoys higher engagement compared to some digital formats.'
    },
    {
      question: 'What types of print media do you recommend for brand visibility?',
      answer: 'We recommend a mix, including magazines, newspapers, brochures, and billboards, depending on your target audience and campaign goals. Each offers unique advantages for brand presence and recall.'
    },
    {
      question: 'Is print advertising more expensive than digital advertising?',
      answer: 'Costs can vary widely. While some print placements can be costly, they may offer broad exposure. Digital ads can be budget-friendly, but costs can rise with more precise targeting or competitive keywords.'
    },
    {
      question: 'Can you customize print media campaigns for local audiences?',
      answer: 'Absolutely. Customizing for local audiences is one of print media"s strengths, especially with local newspapers and magazines that cater to community-specific interests and events.'
    },
    {
      question: 'How does print media integrate with other forms of advertising?',
      answer: 'Print media can seamlessly work with digital campaigns by including QR codes, website URLs, or social media handles, ensuring a multi-channel approach that maximizes audience reach.'
    },
    {
      question: 'What kind of design input can we provide for our print ads?',
      answer: 'We welcome all client input, from branding guidelines to specific imagery. Our design team collaborates closely with clients to ensure the final product aligns with their vision and message.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
