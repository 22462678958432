import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/ott'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import { Helmet } from 'react-helmet';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div>
        <Helmet>
          <title>OTT Advertising | GC Media – Leading OTT Advertising Agency</title>
          <meta name="description" content="GC Media excels in OTT (Over-The-Top) advertising, delivering tailored campaigns that engage your audience on streaming platforms like Mx Player, Hotstar, Zee5 & Jio Cinema. Elevate your brand with us." />
        </Helmet>
      </div>
      <PageHeading
        title='OTT (Over the Top)'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='OTT'
      />
      <Spacing lg='50' md='50' />
      {/* End FunFact Section */}
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/OTT.webp" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">OTT (Over-the-top)</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_45 cs-height_lg_25" />
              <p className="cs-m0 clr-blk">Over-the-top (OTT) advertising services (Mx Player, Hotstar, Zee5 & Jio Cinema) can be a great way to expand your offerings and provide additional value to your audience. OTT advertisements reach a highly engaged audience as streaming services have become incredibly popular in recent years, and many people now prefer to watch TV shows and movies on these platforms.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We believe OTT advertising has the ability to target specific demographics. Streaming services collect a wealth of data on their users, including their age, gender, location, and viewing habits. We acquire preferred information, used to target advertisements to specific demographics, ensuring that you reach the right people with your message.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Leveraging the unique benefits of OTT advertising, we assist our clients in reaching a highly engaged audience, targeting specific demographics, and achieving their marketing goals more effectively. Contact us today to learn more about our services and how we can help you achieve your business goals through powerful  Advertising.</p>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* Start Service Section */}
      <Spacing lg='150' md='80' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
