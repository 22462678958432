import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'How do Smart TV Advertising Services benefit my business?',
      answer: 'Smart TV Advertising Services can benefit your business in several ways, including reaching a large, engaged audience, precise targeting, real-time analytics, and cross-device compatibility. By utilizing these services, your business can deliver tailored ads to consumers, leading to increased brand awareness, higher conversion rates, and better return on investment (ROI).'
    },
    {
      question: 'How do I get started with Smart TV Advertising Services?',
      answer: 'To get started with Smart TV Advertising Services, you can contact us as we specializes in these services. We’ll guide you through the process of creating and managing your Smart TV ad campaigns, including targeting options, ad formats, and pricing structures.'
    },
    {
      question: 'What are the different ad formats available for Smart TV Advertising?',
      answer: 'Some common ad formats for Smart TV Advertising include video ads, interactive ads, sponsored content, and display banners. The choice of format depends on your advertising goals, target audience, and budget. A professional advertising firm can help you determine the best ad format for your campaign.'
    },
    {
      question: 'How does targeting work in Smart TV Advertising?',
      answer: 'Targeting in Smart TV Advertising involves segmenting your desired audience based on various factors such as demographics, interests, and viewing habits. By leveraging data collected from user profiles, streaming platforms, and other sources, we deliver relevant ads to specific audiences, increasing the likelihood of engagement and conversions.'
    },
    {
      question: 'What kind of analytics can I expect from a Smart TV Advertising campaign?',
      answer: 'Smart TV Advertising platforms provide real-time analytics that can help you measure the performance of your ad campaigns. Some common metrics include impressions, reach, click-through rates (CTR), engagement rates, video completion rates, and conversions. These insights help you optimize your campaigns and make data-driven decisions to improve your advertising results.'
    },
    {
      question: 'Can I integrate my Smart TV Advertising campaign with other digital marketing channels?',
      answer: 'Yes, Smart TV Advertising can be integrated with other digital marketing channels such as social media, mobile, and online advertising to create a cohesive and unified marketing strategy. This cross-device compatibility ensures a consistent brand experience across multiple platforms and touchpoints.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
