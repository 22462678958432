import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import { Helmet } from 'react-helmet';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div>
        <Helmet>
          <title>TV Advertisement Services | GC Media – India's Leading TV Ads Agency</title>
          <meta name="description" content="Choose GC Media for impactful TV advertisements in India. We specialise in TV ads and offer creative and practical solutions to elevate your brand presence." />
        </Helmet>
      </div>
      <PageHeading
        title='TV Advertising'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='TV Advertising'
      />
      <Spacing lg='50' md='50' />
      {/* End FunFact Section */}
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/Tv-ads.jpg" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">TV advertising</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_45 cs-height_lg_25" />
              <p className="cs-m0 clr-blk">TV advertising continues to be one of the most popular and effective ways to reach consumers in India. With a wide range of channels and programming options, TV offers the ability to target specific audiences based on factors such as demographics, interests, and viewing habits.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">As a leading agency in the industry, we understand the importance of TV advertising in reaching a large and diverse audience and creating brand awareness that lasts. Our agency has a team of experts, who work closely with clients to understand their unique needs and create customized campaigns that are tailored to their specific objectives. Whether it's increasing brand awareness, promoting a new product, or driving sales, our TV advertising solutions are designed to achieve results.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">If you are looking to take your business to the next level with innovative and effective TV advertising, our agency is here to help. Contact us today to learn more about our services and how we can help you achieve your business goals through powerful TV advertising campaigns.</p>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* Start Service Section */}
      <Spacing lg='150' md='80' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
