import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What is the difference between traditional OOH and DOOH advertising?',
      answer: 'Traditional Out-of-Home (OOH) advertising refers to static, non-digital formats like billboards, posters, and transit ads. Digital Out-of-Home (DOOH) advertising, on the other hand, utilizes digital displays and screens to deliver dynamic, engaging content. DOOH offers several advantages over traditional OOH, such as real-time content updates, advanced targeting capabilities, and measurable performance metrics.'
    },
    {
      question: 'How can DOOH ads be targeted to specific audiences?',
      answer: 'DOOH allows for advanced audience targeting through the use of data and analytics. By analyzing factors such as location, time, demographics, and consumer behavior, we can create highly targeted campaigns that resonate with the intended audience. Additionally, DOOH ads can be geographically targeted based on the locations of the digital displays, ensuring that the ads are relevant to the consumers in that area.'
    },
    {
      question: 'How do you measure the success of a DOOH campaign?',
      answer: 'The success of a DOOH campaign can be measured through a variety of metrics, such as impressions, reach, engagement, and conversions. Additionally, we can use advanced analytics tools to track consumer interactions with the ads, providing valuable insights into the effectiveness of the creative and messaging.'
    },
    {
      question: 'How can DOOH be integrated with other advertising channels?',
      answer: 'DOOH can seamlessly integrate with other advertising channels like social media, mobile, and online to create a cohesive and impactful marketing strategy. By coordinating messaging and creativity across multiple channels, we can amplify the campaigns reach and effectiveness, providing a more holistic brand experience for the audience.'
    },
    {
      question: 'How much does a DOOH campaign cost?',
      answer: 'The cost of a DOOH campaign can vary depending on several factors, including the duration of the campaign, the number of locations, the size and type of digital displays, and the complexity of the creative. Our team will work closely with you to develop a tailored DOOH strategy that aligns with your budget and marketing objectives, ensuring that your investment delivers the maximum possible return.'
    },
    {
      question: 'How quickly can a DOOH campaign be launched?',
      answer: 'One of the advantages of DOOH is its ability to be quickly deployed and updated. Once the creative assets are ready, a DOOH campaign can be launched within a matter of hours or days, depending on the network and inventory availability. This flexibility allows for timely responses to changing market conditions, trends, or events.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
