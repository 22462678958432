import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What is TVC?',
      answer: 'TVC stands for Television Commercial, which is a form of advertising content that is designed to promote a product, service, or brand by broadcasting it on television channels. TVCs can vary in length, style, and format, but typically include a combination of visuals, audio, and messaging to capture viewers attention and encourage them to take action.'
    },
    {
      question: 'How long does it take to produce a TVC?',
      answer: 'The production timeline for a TVC can vary depending on the complexity of the project, the availability of talent and resources, and the advertising firm"s workload. Generally, it can take anywhere from a few weeks to several months to complete a TVC from concept development to final edit. Its essential to communicate your deadlines and expectations with your advertising firm to ensure a smooth and timely production process.'
    },
    {
      question: 'Can I provide input on the creative direction of my TVC?',
      answer: 'Yes, we encourage our clients to be involved in the creative process and provide input on the overall direction of the TVC. Their input and feedback will be invaluable in helping the creative team develop a commercial that effectively communicates their brands message and resonates with their target audience. '
    },
    {
      question: 'How do I choose the right television channels and time slots for my TVC?',
      answer: 'We as advertising firm, will help our clients select the appropriate channels and time slots for their TVC based on their target audience, budget, and marketing objectives. Wel conduct media planning and research to identify the channels and time slots that will offer the highest viewership and engagement rates among their desired audience.'
    },
    {
      question: 'How can I measure the success of my TVC?',
      answer: 'There are several ways to measure the success of a TVC, including tracking key performance indicators (KPIs) such as reach, impressions, and engagement rates. Additionally, we analyze sales data, website traffic, or other relevant metrics to determine whether the TVC has had a positive impact on client’s business. We assist oour client  set realistic goals and expectations for your TVC campaign and provide them with the necessary tools and data to measure its success. '
    },
    {
      question: 'Can a TVC be repurposed for other platforms?',
      answer: 'Yes, a TVC can often be repurposed for use on other platforms such as digital advertising, social media, or in-store displays. Depending on the format and content of the TVC, we help our client adapt it for use across various marketing channels to maximize their investment and ensure a consistent brand message.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
