import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Team from '../Team';

export default function TeamPage() {
  pageTitle('Team');
  const teamData = [
    {
      memberImage: '/images/madan.png',
      memberName: 'Madan Sharma',
      memberDesignation: '(MD & Founder:GC Media Pvt.Ltd.)',
      memberSocial: {
        linkedin: '/',
        twitter: '/',
        youtube: '/',
        facebook: '/',
      },
    },
    {
      memberImage: '/images/arvind.png',
      memberName: 'Arvind Sharma',
      memberDesignation: '(Executive Director)',
      memberSocial: {
        linkedin: '/',
        twitter: '/',
        youtube: '/',
        facebook: '/',
      },
    },
    {
      memberImage: '/images/rupali.png',
      memberName: 'Rupali Choudhary',
      memberDesignation: '(Director – Corp. Accounts)',
      memberSocial: {
        linkedin: '/',
        twitter: '/',
        youtube: '/',
        facebook: '/',
      },
    },
    {
      memberImage: '/images/naveen.png',
      memberName: 'Naveen Joshi',
      memberDesignation: '(Lead - Strategic Planning & Buying)',
      memberSocial: {
        linkedin: '/',
        twitter: '/',
        youtube: '/',
        facebook: '/',
      },
    },
    {
      memberImage: '/images/sandeep.png',
      memberName: 'Sandeep Dixit',
      memberDesignation: '(DGM – Sales & Marketing)',
      memberSocial: {
        linkedin: '/',
        twitter: '/',
        youtube: '/',
        facebook: '/',
      },
    },
    {
      memberImage: '/images/satish.png',
      memberName: 'Satish Rai',
      memberDesignation: '(AGM - Sales & Marketing)',
      memberSocial: {
        linkedin: '/',
        twitter: '/',
        youtube: '/',
        facebook: '/',
      },
    },
    {
      memberImage: '/images/rashmi.png',
      memberName: 'Rashmi Sharma',
      memberDesignation: '(Lead- Finance & Admin )',
      memberSocial: {
        linkedin: '/',
        twitter: '/',
        youtube: '/',
        facebook: '/',
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Our Team"
        bgSrc="images/team_hero_bg.jpeg"
        pageLinkText="Team"
      />
      <Spacing lg="70" md="80" />
      <Div className="container">
        <SectionHeading
          title="Bringing growth to your business <br/> is what our team is all about."
         subtitle=""
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          {teamData.map((item, index) => (
            <Div key={index} className="col-lg-3 col-sm-6">
              <Team
                memberImage={item.memberImage}
                memberName={item.memberName}
                memberDesignation={item.memberDesignation}
                memberSocial={item.memberSocial}
              />
              <Spacing lg="80" md="30" />
            </Div>
          ))}
        </Div>
        <SectionHeading
          title=""
         subtitle="We embrace a talented team of experts from various disciplines to ensure that we are always pushing the boundaries and exploring new opportunities."
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="50" />
        {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
      </Div>
    </>
  );
}
