import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What are the different types of OOH advertising?',
      answer: 'OOH advertising encompasses various formats, including traditional billboards, digital billboards, transit advertising (buses, trains, taxis), street furniture (bus shelters, kiosks, benches), and place-based advertising (shopping malls, cinemas, sports arenas).'
    },
    {
      question: 'Can OOH advertising be integrated with digital marketing efforts?',
      answer: 'Absolutely! OOH advertising can be integrated with digital marketing to create a seamless and multi-channel customer experience. This can be done by incorporating QR codes, social media hashtags, or online URLs on OOH ads, encouraging consumers to engage with your brand through their smartphones.'
    },
    {
      question: 'How do you choose the best locations for an OOH campaign?',
      answer: 'Choosing the right location depends on your target audience, campaign goals, and budget. Advertising firms use data and analytics tools to identify high-traffic areas, consumer demographics, and other key factors to optimize ad placement and reach your desired audience effectively.'
    },
    {
      question: 'How long does it take to launch an OOH campaign?',
      answer: 'The timeline for launching an OOH campaign can vary based on factors like ad format, creative development, and production lead times. Generally, it can take anywhere from a few weeks to a couple of months to fully execute an OOH campaign, from planning to ad placement.'
    },
    {
      question: 'Can OOH advertising be targeted to specific demographics or audiences?',
      answer: 'Yes, OOH advertising can be targeted by leveraging location-based data, audience insights, and consumer behavior patterns. This allows brands to tailor their OOH campaigns to reach specific demographics or market segments more effectively.'
    },
    {
      question: 'What creative elements should be considered when designing an OOH ad?',
      answer: 'When designing an OOH ad, it"s crucial to create a visually striking and easily digestible message. Key elements include a clear and concise headline, bold and contrasting colors, large fonts, captivating visuals, and a strong call to action. The ad should also align with your brand identity and overall marketing strategy.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
