import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/brand'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Brand Building'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText="Brand Building"
      />
     
    
      <Spacing lg='100' md='50'/>
       {/* End FunFact Section */}
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer1 cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/brand.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Brand Building"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0 clr-blk">Brand building is the process of creating and developing a unique and memorable brand identity and reputation that resonates with target audiences and distinguishes a business or product from competitors.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Our goal is to create a consistent and memorable brand experience across all touchpoints, from the product or service itself to the marketing materials, customer service, and overall brand reputation for our clients.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We as a renowned advertising agency, specialize in helping our clients build a strong brand identity and reputation as per the overall perception of the brand by consumers and a willingness to create an emotional connection with the audience continuously. Contact us today to learn more about our brand-building services and how we can help you achieve your business goals through effective advertising campaigns.</p>
              <Spacing lg="30" md="30" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s' 
                subtitle=''
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
