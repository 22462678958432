import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What are the advantages of radio advertising over other mediums?',
      answer: 'Radio advertising offers several benefits, including cost-effectiveness, wide reach, flexibility, and the ability to target specific audiences. Additionally, radio ads can be produced relatively quickly and can create a strong emotional connection with listeners through the power of sound.'
    },
    {
      question: 'What is the typical length of a radio ad?',
      answer: 'Radio ads typically range from 15 seconds to 60 seconds in length. The choice of ad length depends on the message, budget, and advertising goals. Shorter ads are often more cost-effective, while longer ads can provide more information and create a stronger connection with listeners. '
    },
    {
      question: 'What is the process of creating a radio ad with us?',
      answer: 'The process typically begins with a client consultation to discuss goals, target audience, and budget. Next, we will develop a creative concept and write the ad script. Once approved, then we will arrange for voiceover talent and produce the ad. After the ad is completed, the we will work with the client to plan and purchase radio airtime, ensuring the ad reaches the desired audience.'
    },
    {
      question: 'Can I run my radio ad on multiple stations?',
      answer: 'Yes, running your radio ad on multiple stations can increase your reach and potentially target a broader audience. We can help you identify the most appropriate stations for your ad based on audience demographics, station formats, and coverage areas.'
    },
    {
      question: 'How long should I run my radio ad campaign?',
      answer: 'The duration of a radio ad campaign depends on factors such as the clients goals, budget, and the desired frequency of the ads. A short, intensive campaign can create a quick impact,while a longer campaign can build and maintain brand awareness over time. We can recommend the optimal campaign length based on your specific needs and objectives. '
    },
    {
      question: 'Can I update or change my radio ad during the campaign?',
      answer: 'Yes, you can update or change your radio ad during the campaign, although additional production costs may apply. We can help you assess the benefits of making changes and can facilitate the process of updating your ad to maximize its effectiveness.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
