import React, { useEffect } from 'react';
import CaseStudyGallery from '../Gallery/CaseStudyGallery';
import Spacing from '../Spacing';
import { pageTitle } from '../../helper';
import PageHeading from '../PageHeading';
import { Helmet } from 'react-helmet';

export default function CaseStudy() {
  pageTitle('Case Study');

  return (
    <>
      <div>
        <Helmet>
          <title>Case Studies | GC Media – Insights into Successful Campaigns</title>
          <meta name="description" content="Explore GC Media's case studies to understand how we've achieved success for our clients through various advertising and marketing strategies." />
        </Helmet>
      </div>
      {/* Start Page Heading Section */}
      <PageHeading
        title="Case Study"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="CaseStudy"
      />
      {/* End Page Heading Section */}



      {/* Start Gallery Section */}
      <Spacing lg="50" md="80" />
      <CaseStudyGallery />
      {/* End Gallery Section */}
      <Spacing lg="65" md="40" />
    </>
  );
}
