import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/strategy'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Strategy Planning'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText="Strategy Planning"
      />
     
    
      <Spacing lg='100' md='50'/>
       {/* End FunFact Section */}
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer1 cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/strategy.png"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Strategy Planning"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0 clr-blk">Our team of experienced professionals offers silver-bullet advertising services across a wide range of industries. As a leader in innovative and effective business solutions, has over 25 years of experience in assisting our clients to reach their business goals.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We offer a comprehensive range of advertising services, including branding, Print, TV, digital, OTT, radio, and outdoor marketing, and social media management. The ability to combine the talents of our talented team of experts from different disciplines ensures that we are always pushing the boundaries and exploring new possibilities.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Allied as a 360-degree advertising agency, offer out-of-the-box and integrated solutions in accordance with the needs of our clients and harness technology and creativity to increase their brand awareness. In order to build brands through detailed audits, market strategies, achieving stable scalability, and maintaining consistency, we curated our advertising company with full transparency.</p>
              <Spacing lg="30" md="30" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s' 
                subtitle=''
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
