import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import LogoList from '../LogoList'

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Dynamic Website Design'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />
      {/* Start Service Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style11">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Dynamic Website Design"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Founded in 1997, we have over 25 years of experience in the media and advertising field. We are a result-driven, fully INS Acc. Advertising Agency. GC Media believes in creating out-of-the-box solutions that can help in creating successful brand journeys in the consumer marketplace. Being a 360-degree Integrated advertising agency, we provide tailor-made, comprehensive solutions as per the requirements of our clients. We amalgamate technology and creativity that can help you boost your brand. 
              GC Media is a complete media and advertising agency in Delhi NCR. Our team nurtures relationships and drives your business towards growth. We have expertise in media buying, print media, brand building, outdoor advertising, digital media marketing, web design, and development.
              Our advertising company has been curated with full transparency and we hold years of experience in handling iconic brands like Sonalika, Cremica, Today Tea, Denver, All Seasons and Uniexperts. As an advertising company, we believe in creating brands through 4 key rules- detailed audits, market strategies, achieving stable scalability, and maintaining consistency.
                
              </p>
              <Spacing lg="15" md="15" />
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="-18" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      
      
            
         
      {/* Start Service Section */}
      <Div className="container">
        
        <LogoList/>
      </Div>
      <Spacing lg="150" md="80" />
      {/* Start Service Section */}
   
     
      <TestimonialSlider />
      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
