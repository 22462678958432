import React from 'react';
import SectionTitle from '../elements/section-title/SectionTitle';


const Datas = [
    {
        id: 1 ,
        title: "1.High Frequency and Recall:",
        para: "Radio ads have the advantage of being played repeatedly throughout the day, increasing the likelihood of your message being heard and remembered by the audience. Research shows that radio has a higher ad recall rate compared to other mediums, making it an effective tool for building brand awareness and driving conversions."
    },
    {
        id: 2,
        title: "2.Cost-Effective Advertising:",
        para: "Radio advertising is an affordable alternative to other traditional advertising mediums, such as TV and print. With lower production costs and competitive pricing for ad spots, businesses can achieve maximum exposure without breaking the bank. Our ad agency works closely with clients to develop cost-effective radio campaigns that deliver maximum return on investment (ROI)."
    },
    {
        id: 3,
        title: "3.Emotional Connection:",
        para: "Radio's ability to evoke emotions and create a personal connection with listeners is unparalleled. With the right mix of voice, music, and storytelling, radio ads can create memorable and persuasive messages that engage the audience on an emotional level. Our creative team crafts compelling radio ads that resonate with listeners and motivate them to take action."
    },
    {
        id: 4,
        title: "4.Wide Reach and Local Impact:",
        para: "Despite the growth of digital platforms, radio continues to enjoy a vast and diverse audience in India. According to the Indian Readership Survey (IRS) 2019, 16% of the country's population listens to the radio daily. This presents a significant opportunity for advertisers to reach a wide audience across urban and rural areas, while also delivering targeted messages to specific regions and communities.Ut id orci ultricies, gravida arcu ac, elementum eros. Curabitur tortor ipsum, imperdiet eget odio ac, faucibus ultricies nisl. Maecenas scelerisque dignissim elit."

    },
    {
        id: 5,
        title: "5.Flexible and Agile:",
        para: "Radio advertising allows for quick and easy modifications to your campaign, enabling you to respond to market changes or new developments in real-time. Our ad agency ensures that your radio ads remain relevant and effective, helping you stay ahead of the competition.In conclusion, radio advertising in India continues to be a powerful medium that offers numerous benefits to advertisers. With our ad agency's expertise, we can help you create captivating radio campaigns that deliver measurable results and drive business growth. Don't miss out on the opportunity to connect with your audience through the timeless charm of radio – contact us today to begin your radio advertising journey!"
    }
]

const AboutThree = () => {
    return (
        <div className="section section-padding bg-color pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    title="Rediscover the Power of Radio Advertising in India – Unleashing Benefits for Advertisers"
                    //subtitle=": Explore how radio advertising continues to be a powerful medium in India, offering unmatched benefits to advertisers. Learn how our ad agency can help you tap into its potential!"
                    description="Explore how radio advertising continues to be a powerful medium in India, offering unmatched benefits to advertisers. Learn how our ad agency can help you tap into its potential!"
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                {/* <h3 className="sl-number">{data.id}</h3> */}
                                <h3 className="title">{data.title}</h3>
                                {/* <p>{data.para}</p> */}
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default AboutThree;