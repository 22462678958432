import React from 'react';
import SectionTitle from '../section-title/SectionTitleNew';
import BrandItem from './BrandItem';


const BrandOne = () => {
    return (
        <div className="section section-padding-2 bg-color-dark">
        <div className="container">
            {/* <SectionTitle 
                subtitle="Top Clients"
                title="We’ve built solutions for..."
                description=""
                textAlignment="heading-light-left"
                textColor=""
            /> */}
            <div className='row'>
               <BrandItem />
            </div>
        </div>
    </div>
    )
}

export default BrandOne;