import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SectionTitle from '../section-title/SectionTitleNew'
import ServicePropOne from '../service/ServicePropOne'
import ProcessOne from '../process/ProcessOne'

export default function ServiceDevelopment() {
  pageTitle('Service Development Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Development'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg="150" md="80" />
            <div className="section section-padding bg-color-light">
                <div className="container">
                    <SectionTitle 
                        subtitle="What We Can Do For You"
                        title="Services we can <br> help you with"
                        description=""
                        textAlignment="heading-left"
                        textColor=""
                    />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" itemShow="6" marginTop="no" />
                    </div>
                </div>
            </div>

            {/* <AboutThree /> */}
            {/* <ProcessOne/> */}
            
            {/* <CtaLayoutOne /> */}
        
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/service_img_1.jpeg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Below our most design related services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='#' btnText='MySql' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Shopify' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='WordPress' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Magento' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Prestashop' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='React js' variant='cs-type2'/>
                <Spacing lg='0' md='10'/>
              </Div>
              <Div className="col-lg-6">
                <Button btnLink='#' btnText='PHP' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Python' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Node js' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='AWS' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Azure' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <TestimonialSlider />
      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
