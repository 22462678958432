import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What is Brand Building?',
      answer: 'Brand building is the strategic process of creating awareness, establishing and promoting a clear image of your brand in the market. The aim is to create a unique identity in the minds of consumers, one that is consistent across all marketing channels and touchpoints.'
    },
    {
      question: 'Why is Brand Building Important?',
      answer: 'Brand building is essential for several reasons:Consumer Trust: A strong brand can inspire consumer trust and loyalty.Market Differentiation: Sets your products or services apart from competitors.Premium Pricing: Enables you to command higher prices than non-branded or generic products.Customer Retention: Strong brands usually enjoy higher levels of customer retention.Competitive Advantage: A strong brand can give you a substantial edge in the market.'
    },
    {
      question: 'What Are the Core Elements of Brand Building?',
      answer: 'Key elements in brand building include:Brand Strategy: An overarching plan that defines what your brand stands for.Brand Identity: The visual elements of your brand, such as logo, color scheme, and typography.Brand Voice: The tone and language style used in your communication.Brand Positioning: How your brand is positioned in the market relative to competitors.Customer Experience: The perception customers have when interacting with your brand.'
    },
    {
      question: 'How to Differentiate Your Brand from Competitors?',
      answer: 'Differentiating your brand involves focusing on elements that make you unique and valuable to your target audience. This could be superior quality, customer service, pricing strategy, or even your brand story. Use your Unique Selling Proposition (USP) to set yourself apart from competitors in all your marketing materials.'
    },
    {
      question: 'What is the Role of Media in Brand Building?',
      answer: 'The media plays a crucial role in brand building for businesses and organizations. Through various channels such as newspapers, television, radio, social media, and blogs, media can influence public perception, establish credibility, and drive consumer engagement. Here are some ways in which the media contributes to brand building'
    },
    {
      question: 'How Do You Measure the Success of Brand Building?',
      answer: 'The success of brand building can be measured through various Key Performance Indicators (KPIs):Brand Awareness: Measures how many people are familiar with your brand.Brand Recall: The ability of consumers to recognize your brand without prompting.Customer Loyalty: The likelihood of customers returning or recommending your brand.Net Promoter Score (NPS): A metric that gauges customer satisfaction and loyalty.Social Engagement: Metrics like likes, shares, and comments on social media platforms.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
