import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/infulencer'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import AboutFour from '../counterup/AboutFour'
import { Helmet } from 'react-helmet';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div>
        <Helmet>
          <title>Influencer Marketing | GC Media – Elite Influencer Marketing Agency</title>
          <meta name="description" content="Turn to GC Media for industry-leading Influencer Marketing Services. We connect your brand with influencers who resonate with your target audience. Learn more." />
        </Helmet>
      </div>
      <PageHeading
        title='Influencer Marketing'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='Influencer Marketing'
      />
      <Spacing lg='50' md='50' />
      {/* End FunFact Section */}
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/Influencer Marketing.webp" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">Influencer Marketing</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Collaborating with influencers on social media to promote the brands to their audience is a popular and effective way for businesses to reach new audiences, build brand awareness, and increase sales. This involves creating custom content such as sponsored posts, videos, or stories, and providing influencers with specific messaging and guidelines to ensure that the campaign is consistent with the client's branding. </p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We as digital experts, specialize in influencer marketing by partnering with influencers who are seen as authoritative and trustworthy in their niche and tap into their respective audiences. Typically monitoring and analyzing influencer marketing, our campaigns are prepared closely to ensure that they are generating a positive return on investment. </p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Partnering with relevant influencers and developing effective campaigns that resonate with our client’s audiences, we help our clients to achieve their business objectives and stay ahead of the competition. Contact us today to learn more about our services and how we can help you achieve your business goals through powerful PPC Advertising.</p>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='100' md='45' />
      {/* <AboutFour/> */}

      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
