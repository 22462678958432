import React from 'react'
import { Icon } from '@iconify/react';

export default function ContactInfoWidgetT({withIcon, title1}) {
  return (
    <>
      {title1 && <h2 className="cs-widget_title">{title1}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
          Delhi (Registered Office) | Lucknow | Jaipur | Mumbai 
        </li>
        
        
      </ul>
    </>
  )
}
