import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/tvc'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import { Helmet } from 'react-helmet';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div>
        <Helmet>
          <title>TVC Ad Production | GC Media – Your Go-To TVC Ad Production Agency</title>
          <meta name="description" content="At GC Media, we specialize in TVC Ad Production, creating compelling ads that resonate with your target audience. Reach out to us today." />
        </Helmet>
      </div>
      <PageHeading
        title='TVC Production'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='TVC Production'
      />
      <Spacing lg='50' md='50' />
      {/* End FunFact Section */}
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/TVC.webp" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">TVC</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_45 cs-height_lg_25" />
              <p className="cs-m0 clr-blk">Every TVC is an opportunity to create something truly memorable and impactful to capture attention, engage audiences, and drive business growth. We believe that creativity, innovation, and attention to detail are the keys to producing successful TVCs.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We understand the importance of effective communication and collaboration in TVC production as we bring together a team of talented designers, writers, directors, and editors who are passionate about their work and dedication to delivering exceptional results. Our team of experienced professionals works closely with clients to understand their unique needs and develop TVC concepts that are tailored to their specific goals.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We are committed to delivering high-quality TVC production services that are customized to our client’s specific needs and objectives. Contact us today to learn more about our services and how we can help you achieve your business goals through powerful TVC advertising.</p>
              <Div className="cs-height_20 cs-height_lg_20" />

            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='145' md='80' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
