import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/media'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading
        title='Media Buying'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='Media Buying'
      />


      <Spacing lg='100' md='50' />
      {/* End FunFact Section */}
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer1 cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/Media Buying.jpg"
                  alt="Meida Buying"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Media Buying"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0 clr-blk">To reach the target audience effectively and efficiently, media buying involves selecting and buying the appropriate media channels, such as TV, radio, print, outdoor, and digital.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">It's common for the media buying process to include several stages, including research, negotiation, placement, and monitoring. We employ tools and techniques to research the market, identify the most effective media platforms for reaching the target audience, and negotiate the best rates for media placement.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Our media buying services are designed to be comprehensive and flexible, allowing our clients to customize their media buying experience to fit their specific needs and budget constraints.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">Effective media buying is crucial to the success of any advertising campaign, and we are committed to delivering the best possible results for our clients. Contact us today to learn more about our media buying services and how we can help you achieve your business goals through effective media buying.</p>
              <Spacing lg="30" md="30" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      <Spacing lg='145' md='80' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
