import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What kind of web development services do you offer?',
      answer: 'We offer a wide range of web development services, including custom website design, responsive web design, e-commerce development, content management system (CMS) integration, website maintenance and support, and search engine optimization (SEO).'
    },
    {
      question: 'How do you ensure that your website will be user-friendly and accessible on all devices?',
      answer: 'Our web development experts create responsive websites that adapt to various screen sizes and devices, ensuring that your website will look and function optimally on smartphones, tablets, and desktop computers. We also adhere to web accessibility standards to ensure that users with disabilities can access and navigate your website with ease.'
    },
    {
      question: 'How long does it typically take to develop a website from start to finish?',
      answer: 'The timeline for web development projects can vary based on factors such as project complexity, client requirements, and availability of resources. However, a standard website typically takes around 6-10 weeks from concept to completion. We will provide you with a detailed project timeline during the planning stage. '
    },
    {
      question: 'How much will it cost to develop my website?',
      answer: 'The cost of developing a website can vary widely depending on your specific requirements and the complexity of your project. Factors affecting the cost include design, functionality, content creation, and any additional services such as SEO or ongoing maintenance. We will provide you with a detailed quote once we have a clear understanding of your needs.'
    },
    {
      question: 'Will you provide training on how to manage and update my website?',
      answer: 'Absolutely! We will provide you with comprehensive training on how to use the content management system (CMS) of your website, allowing you to make updates and manage your content with ease. We also offer ongoing support and maintenance services if you need additional assistance. '
    },
    {
      question: 'Can you help improve my website"s search engine rankings?',
      answer: 'Yes, we offer search engine optimization (SEO) services to help your website rank higher in search engine results. Our SEO experts will conduct keyword research, optimize your websites content and structure, and implement a variety of on-page and off-page SEO strategies to improve your online visibility and drive organic traffic to your site.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
