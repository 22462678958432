import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What is Media Buying?',
      answer: 'Media buying is the process of acquiring advertising space or time across various media channels. These channels include but are not limited to television, radio, newspapers, magazines, and online platforms. The goal is to identify and purchase ad placements where your target audience is most likely to engage with your brand.'
    },
    {
      question: 'What Are the Types of Media Buying?',
      answer: 'There are various types of media buying, each with its own set of advantages:Traditional Media Buying: Involves purchasing ad space in traditional channels like TV, radio, and print.Digital Media Buying: Focuses on buying digital advertising space, such as online banners, social media ads, and PPC campaigns.Programmatic Media Buying: Automated method of buying or auctioning digital advertising, often in real-time.Direct Media Buying: Deals are made directly with media owners without intermediaries.'
    },
    {
      question: 'How Does Programmatic Media Buying Work?',
      answer: 'Programmatic media buying uses automated technology to purchase digital advertising space. Instead of traditional human negotiations, programmatic software uses real-time data to decide where your ads will be most effectively placed. This process typically involves a Demand Side Platform (DSP) and Supply Side Platform (SSP).'
    },
    {
      question: 'What is the ROI of Media Buying?',
      answer: 'Return on Investment (ROI) in media buying refers to the revenue generated by your advertising campaigns compared to the cost of the media space purchased. A successful media buying campaign should aim to achieve a positive ROI, where the revenue gained surpasses the investment made.'
    },
    {
      question: 'How Do You Measure Media Buying Success?',
      answer: 'Success in media buying is often measured through Key Performance Indicators (KPIs) like impressions, clicks, conversions, Reach and ROI. These metrics can help you understand the effectiveness of your media buying strategy and make data-driven decisions for future campaigns.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
