import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'How do you identify the right influencers for our brand?',
      answer: 'We carefully analyze your brands target audience, industry, and objectives to identify influencers who align with your values and have a similar follower demographic. We also consider factors such as the influencers engagement rate, content quality, and overall reputation to ensure they will provide a positive impact on your brand.'
    },
    {
      question: 'What are the different types of influencer marketing campaigns?',
      answer: 'Some common types of influencer marketing campaigns include sponsored content, product reviews, giveaways, affiliate marketing, and influencer takeovers. The choice of campaign type depends on your goals, budget, and the influencers preferences. We will work closely with you to develop a customized campaign that meets your needs.'
    },
    {
      question: 'How do you measure the success of an influencer marketing campaign?',
      answer: 'We track various key performance indicators (KPIs) such as engagement rate, impressions, reach, website traffic, and conversion rates. These metrics help us gauge the effectiveness of the campaign and make any necessary adjustments to optimize its performance.'
    },
    {
      question: 'How much does influencer marketing cost?',
      answer: 'Influencer marketing costs can vary widely based on factors such as the influencers popularity, campaign duration, and content format. We will work with you to develop a campaign that meets your budget and objectives, and we will provide a transparent pricing structure for your approval before moving forward.'
    },
    {
      question: 'Can you provide examples of successful influencer marketing campaigns you have managed?',
      answer: 'Yes, we have an extensive portfolio of successful influencer marketing campaigns across various industries. We will be happy to share case studies and testimonials to demonstrate our expertise and the results we have achieved for our clients.'
    },
    {
      question: 'How long does it take to see results from an influencer marketing campaign?',
      answer: 'The timeline for seeing results can vary depending on factors such as campaign goals, influencer engagement, and the type of content created. Generally, you can expect to see an increase in brand awareness, website traffic, and conversions within a few weeks to a couple of months after launching the campaign. However, it is essential to continually monitor and optimize the campaign to achieve long-term success.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
