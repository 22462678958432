import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/ooh'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import { Helmet } from 'react-helmet';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div>
        <Helmet>
          <title>OOH Advertising – Leading OOH Advertising Agency for Your Brand</title>
          <meta name="description" content="Choose GC Media for exceptional OOH (Out of Home) advertising solutions. With our expertise, we help brands make a significant impact in public spaces on properties like Unipole, Hoarding, Metro, Auto, bus shelters & Cab branding" />
        </Helmet>
      </div>
      <PageHeading
        title='OOH (Out of Home)'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='OOH'
      />
      <Spacing lg='50' md='50' />
      {/* End FunFact Section */}
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/OHH.jpg" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">OOH</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_45 cs-height_lg_25" />
              <p className="cs-m0 clr-blk">With the rise of urbanization and mobile lifestyles, OOH (Out-of-Home) advertising has become an increasingly popular and effective way to reach consumers. OOH advertising refers to any type of advertising that reaches consumers while they are outside of their homes. This can include billboards, posters, transit advertising, street furniture, and more. </p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">As an advertising agency, we specialize in providing innovative and effective OOH advertising (Unipole, Hoarding, Metro, Auto, bus shelters & Cab branding) to reach audiences on the go and create brand awareness that lasts. Whether it's increasing brand awareness, promoting a new product, or driving sales, our OOH advertising solutions are designed to achieve results. Our designers, strategists, and copywriters work collaboratively to create impactful OOH advertising campaigns that capture attention and drive engagement</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">If you are looking to take your business to the next level with innovative and effective OOH advertising, our agency is here to help. Contact us today to learn more about our services and how we can help you achieve your business goals through powerful OOH advertising campaigns.</p>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* Start Service Section */}
      <Spacing lg='150' md='80' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
