import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'How can digital marketing help my business?',
      answer: 'Digital marketing is the promotion of products or brands through various online channels, including search engines, social media, email, and content marketing. It can help your business by increasing brand awareness, driving targeted traffic, generating leads, and improving customer engagement.'
    },
    {
      question: 'What types of digital marketing services do we offer?',
      answer: 'We offer a wide range of services, including Search Engine Optimization (SEO), Pay-Per-Click (PPC) advertising, social media marketing, content marketing, email marketing, and analytics and reporting.'
    },
    {
      question: 'How do you determine the proper budget for my digital marketing campaign?',
      answer: 'We"ll work with you to understand your business goals, target audience, and competitive landscape to develop a customized budget that maximizes ROI and ensures your marketing efforts are cost-effective.'
    },
    {
      question: 'What is the importance of having a mobile-responsive website for my digital marketing efforts?',
      answer: 'A mobile-responsive website ensures that your site looks and functions well on all devices, including smartphones and tablets. This is crucial for user experience and search engine rankings, as a majority of online traffic comes from mobile devices.'
    },
    {
      question: 'How will you communicate with me throughout the campaign?',
      answer: 'We"ll establish a regular communication schedule to provide updates, discuss progress, and address any questions or concerns. We can use your preferred methods of communication, such as email, phone calls, or video conferencing.'
    },
    {
      question: 'Can you help me with creating engaging content for my website and social media platforms?',
      answer: 'Yes, we have a team of experienced content creators who specialize in crafting engaging, SEO-optimized content that resonates with your target audience and promotes your brand effectively.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title clr-red">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in clr-red">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
