import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion/print'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import { Helmet } from 'react-helmet';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div>
        <Helmet>
          <title>Print Media Solutions – Expertise in Traditional & Modern Print & Newspaper Advertising</title>
          <meta name="description" content="Discover high-quality print media (Newspaper Advertisig) services for your business needs. From traditional to modern print solutions, we offer comprehensive options for effective reach." />
        </Helmet>
      </div>
      <PageHeading
        title='Print Media'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />

      <Spacing lg='80' md='50' />
      {/* End FunFact Section */}
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/Print.webp" alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title clr-red">Print Media</h2>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_45 cs-height_lg_25" />
              <p className="cs-m0 clr-blk">Print media advertising has long been a powerful tool for reaching audiences in India. With a rich history of traditional media consumption, print media continues to be a trusted source of information for many people. Our agency recognizes the value of print media and has a team of experts dedicated to delivering high-quality print ads that capture attention and drive results.
              </p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">We offer a comprehensive range of print media advertising services, including Newspaper ads, Magazine ads. Our team of experienced professionals works closely with clients to understand their unique needs and create customized campaigns that are designed to achieve their specific goals. Whether it's driving sales, increasing brand awareness, or launching a new product, our print media advertising services are designed to help businesses of all sizes achieve success.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">At our agency, we believe that creativity, innovation, and attention to detail are the keys to delivering successful print media advertising campaigns. We bring together a talented team of designers, copywriters, and strategists who are passionate about their work and dedicated to delivering exceptional results. We stay up-to-date with the latest trends and best practices in the industry to ensure that our clients are always one step ahead of the competition.</p>
              <Div className="cs-height_20 cs-height_lg_20" />
              <p className="cs-m0 clr-blk">If you are looking to take your business to the next level with print media advertising, our agency is here to help. Contact us today to learn more about our services and how we can help you achieve your business goals through powerful print media advertising campaigns.</p>
              <Div className="cs-height_45 cs-height_lg_30" />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* Start Service Section */}
      <Spacing lg='150' md='80' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking to make an impact <br/> in the <i>advertising world?</i> "
          btnText="Let’s talk"
          btnLink="https://www.gcmedia.in/contact/"
          target='_top'
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <Spacing lg="70" md="70" />
      {/* End CTA Section */}
    </>
  )
}
